import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { loadModels, getFullFaceDescription, extractFaceFromBox, createMatcher } from '../../../../api/face';
import io from "socket.io-client";
import swal from 'sweetalert';
import axios from 'axios';
import { SemipolarLoading } from 'react-loadingg';

// Import face profile
const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 160;


class FaceRecognitionOut_v2 extends Component {
    constructor(props) {
        super(props);
        this.webcam = React.createRef();
        this.state = {
            fullDesc: null,
            detections: null,
            descriptors: null,
            faceMatcher: null,
            match: null,
            face: null,
            company_logo: "",
            count : 0,
            device_code : null,
            socket_api : null,
            ws : null,
            isInit : true,
        };
    }


    componentDidMount = async () => {

        await loadModels();
        // this.setState({ faceMatcher: await createMatcher(JSON_PROFILE) });
        this.setInputDevice();
        
        let company = JSON.parse(localStorage.getItem("company_data"));
        let device_code = localStorage.getItem("device_id");
        let socket_api = localStorage.getItem("socket_api");

        let ws = new WebSocket(socket_api);
        this.setState({
            company_logo: company.company_logo,
            device_code : device_code,
            socket_api : socket_api,
            ws : ws
        });

        ws.onopen = () => {
            console.log("websocket reconnected");
        };
       

    }

    setInputDevice = () => {
        navigator.mediaDevices.enumerateDevices().then(async devices => {
            let inputDevice = await devices.filter(
                device => device.kind === 'videoinput'
            );
            if (inputDevice.length < 2) {
                await this.setState({
                    facingMode: 'user'
                });
            } else {
                await this.setState({
                    facingMode: { exact: 'environment' }
                });
            }
            this.startCapture();
        });
    };

    startCapture = () => {
        this.interval = setInterval(() => {
            this.capture();
        }, 800);
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    capture = async () => {
        if (!!this.webcam.current) {

            // Get face Detection Box
            await getFullFaceDescription(
                this.webcam.current.getScreenshot(),
                inputSize
            ).then(fullDesc => {
                if (!!fullDesc) {
                    this.setState({
                        isInit : false,
                        detections: fullDesc.map(fd => fd.detection),
                        descriptors: fullDesc.map(fd => fd.descriptor)
                    });

                    // Extract face from webcam
                    let customer_id = this.props.match.params.customer_id;

                    let detections = fullDesc.map(fd => fd.detection);
                    if (detections.length > 0) {
                        let data = { "command": "face_recognize", "image": this.webcam.current.getScreenshot() };
                        // this.connect(data);
                    
                        let ws = this.state.ws;
                        ws.send(JSON.stringify(data));
                        ws.onmessage = function (event) {
                            let mes = JSON.parse(event.data);
                            let faces = mes.message;
                            // console.log(faces);
                            if (Array.isArray(faces)) {
                                let visitor_id = faces.map((face) => face.visitors);
                                let image_id = faces.map((face) => face.userid);
                                localStorage.setItem('face_id', visitor_id[0]);
                                localStorage.setItem('image_id', image_id[0]);
                            }
                            else {
                                document.getElementById('message').innerHTML = faces;
                                localStorage.setItem('face_unknown', faces);
                            }
                        };

                        let face_id = localStorage.getItem('face_id');
                        if (face_id !== null) {
                            let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/checkout/" + customer_id;
                            const url = "/" + customer_id + "/" + this.state.device_code;
                            // // redirect                        
                            let bodyFormData = new FormData();

                            bodyFormData.append('visitor_id', face_id);
                            bodyFormData.append('code', 'no-id');
                            axios({
                                method: "post",
                                url: api_url,
                                data: bodyFormData,
                                headers: { "Content-Type": "multipart/form-data",
                                            "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
                                        },
                            })
                            .then(res => {
                                // console.log(res.data);
                                if (res.status === 200) {
                                    this.setState({
                                        isRedirect: true,
                                        message: ''
                                    });
                                    const audioEl = document.getElementsByClassName("audio-element")[0]
                                    audioEl.play();
                                    swal({
                                        icon: "success",
                                        title: "Success!",
                                        text: "CẢM ƠN QUÝ KHÁCH '" + res.data.visitor_name + "'. XIN VUI LÒNG LIÊN HỆ QUẦY LỄ TÂN ĐỂ NHẬN LẠI GIẤY TỜ TÙY THÂN!",
                                        type: "success",
                                        timer: 3000,
                                        showConfirmButton: false
                                    }).then(function () {

                                        window.location.href = url;
                                    });
                                } else if (res.status === 205) {
                                    this.setState({
                                        message: ''
                                    });
                                    let url_err = "/checkout/module/" + customer_id;

                                    swal({
                                        icon: "error",
                                        title: "Lỗi 205!",
                                        text: 'Không tìm thấy dữ liệu. Bạn chưa đăng ký gặp!!!',
                                        type: "error",
                                        timer: 3000,
                                        showConfirmButton: false
                                    }).then(function () {
                                        window.location.href = url_err;
                                    });
                                }
                            })
                            .catch(error => {
                                console.log(JSON.parse(error));
                            })
                        }

                        let face_unknown = localStorage.getItem('face_unknown');
                        if (face_unknown === 'unknown') {
                            let count = this.state.count + 1;
                            this.setState({
                                count: count,
                            });
                            // console.log(count);
                            if (this.state.count >= 8) {
                                localStorage.removeItem('face_unknown');
                                let url_err = "/checkin/visitor-sign/" + this.props.match.params.customer_id;
                                swal({
                                    icon: "error",
                                    title: "Lỗi 500!",
                                    text: 'Không tìm thấy dữ liệu. Vui lòng đăng ký mới!!!',
                                    type: "error",
                                    timer: 3000,
                                    showConfirmButton: false
                                }).then(function () {
                                    window.location.href = url_err;
                                });
                            }
                        }
                    }

                }
            });


            if (!!this.state.descriptors && !!this.state.faceMatcher) {
                let match = await this.state.descriptors.map(descriptor =>
                    this.state.faceMatcher.findBestMatch(descriptor)
                );
                this.setState({ match });
            }
        }
    };

    render() {
        const { detections, match, face } = this.state;
        let videoConstraints = null;
        videoConstraints = {
            width: WIDTH,
            facingMode: 'user'
        };

        let drawBox = null;
        if (!!detections) {

            drawBox = detections.map((detection, i) => {
                let _H = detection.box.height;
                let _W = detection.box.width;
                let _X = detection.box._x;
                let _Y = detection.box._y;

                return (
                    <div key={i}>
                        <div
                            style={{
                                border: 'solid',
                                borderColor: 'green',
                                height: _H,
                                width: _W,
                                transform: `translate(${_X}px,${_Y}px)`
                            }}
                        >
                            <p id="message" style={{ fontWeight: 'bold',color:'green' }}></p>
                            {!!match && !!match[i] ? (
                                <p
                                    style={{
                                        backgroundColor: 'green',
                                        border: 'solid',
                                        borderColor: 'green',
                                        marginTop: 0,
                                        color: '#fff',
                                        transform: `translate(-3px,${_H}px)`
                                    }}
                                >
                                    {match[i]._label}
                                </p>
                            ) : null}
                        </div>
                    </div>
                );
            });
        }

        return (

            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card bg-secondary border-0 mb-0">
                                    <div className="card-body px-lg-5 py-lg-5">
                                        <div className="text-center">
                                            <img style={{ marginTop: "4px", marginBottom: "4px" }} width="200px" height="200px" src={ this.state.company_logo } alt="" /><br />
                                            <div className="row justify-content-center">
                                                <div className="col-xl-6">
                                                    <div
                                                        className="Camera"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: HEIGHT
                                                            }}
                                                        >
                                                            <div>
                                                                { this.state.isInit && <p>Đang khởi tạo. Vui lòng đợi...</p> }
                                                                <h3 className="text-warning">Vui lòng tháo khẩu trang!</h3>
                                                                {!!videoConstraints ? (
                                                                    <div style={{ position: 'absolute' }}>
                                                                        
                                                                        <Webcam
                                                                            audio={false}
                                                                            width="100%"
                                                                            ref={this.webcam}
                                                                            screenshotFormat="image/jpeg"
                                                                            videoConstraints={videoConstraints}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                                {!!drawBox ? drawBox : null}
                                                                { this.state.isInit && <SemipolarLoading color={'#33D7FF'}/> }
                                                            </div>
                                                            {/* <img src={this.state.face}/> */}
                                                        </div>
                                                    </div>
                                                    <audio className="audio-element">
                                                        <source src="/assets/sound-effect-GOODBYE.mp3" type="audio/mpeg" />
                                                    </audio>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(FaceRecognitionOut_v2);