import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { withTranslation } from 'react-i18next';


class Vaccine extends Component {

    constructor(props){
        super(props);
        this.state = {
            vaccinated : false,
            isRedirect : false,
            isBlocking : false,
        }
    }

    hasVaccinated = () => {
        this.setState({
            vaccinated : true
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();


        let visitor_id = localStorage.getItem('visitor_id');
        let customer_id = this.props.match.params.customer_id;
        let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store-vaccine/" + customer_id + "/" + visitor_id;
        let bodyFormData = new FormData();
        bodyFormData.append('cb_injection_1', event.target.cb_injection_1.value);
        bodyFormData.append('dt_injection_1', event.target.dt_injection_1.value);
        if(event.target.img_injection_1.files[0] !== undefined){
            bodyFormData.append('img_injection_1', event.target.img_injection_1.files[0]);
        }
        if(event.target.img_injection_2.files[0] !== undefined){
            bodyFormData.append('img_injection_2', event.target.img_injection_2.files[0]);
        }
        bodyFormData.append('cb_injection_2', event.target.cb_injection_2.value);
        bodyFormData.append('dt_injection_2', event.target.dt_injection_2.value);

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data"},
        })
        .then(result => {
            if(result.data.status_code === 200){
                // console.log(result.data);

                event.target.reset();
                this.setState({
                  isBlocking : false,
                  isRedirect : true, 
                });
            }
            else if(result.data.status_code === 404)
            {
                let errors = result.data.error;
                let error_str = "";
                if('img_injection_1' in errors)
                {
                    error_str += errors.img_injection_1[0] + "<br>"; 
                }
                if('img_injection_2' in errors)
                {
                    error_str += errors.img_injection_2[0] + "<br>"; 
                }
                if('dt_injection_1' in errors)
                {
                    error_str += errors.dt_injection_1[0] + "<br>" ;
                }
                if('dt_injection_2' in errors)
                {
                    error_str += errors.dt_injection_2[0] + "<br>" ;
                }
                return Swal.fire('Lỗi 404!', error_str, "error");
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })

    }

    renderVaccine(){

        const { t } = this.props;

        let url = "/checkin/type-employee/" + this.props.match.params.customer_id;

        if(this.state.vaccinated === false)
        {
            return <>
                    <div className="form-group">
                        <h1>{t('vaccine-des')}</h1>
                    </div>

                    <div className="form-group">
                        <button onClick={ this.hasVaccinated } id="btn-yes" className="btn btn-success">{t('vaccine-yes')}</button>
                        <Link to={ url }><button id="btn-no" className="btn btn-danger">{t('vaccine-no')}</button></Link>
                    </div>
            </>
        }
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }


    render() {
        const { t } = this.props;

        if(this.state.isRedirect)
        {
            let url = "/checkin/type-employee/" + this.props.match.params.customer_id;
            return <Redirect to={ url } />
        }



        let elmForm = null;
        let injection_vaccine = null;
        let vaccinated = this.state.vaccinated;

        if(vaccinated === true)
        {
            injection_vaccine = <>
            <div className="row">
                <div className="form-group col-xl-6">
                    <h3 className="text-left">{t('vaccine.injection')} 1</h3>
                    <select className="form-control" name="cb_injection_1" onChange={ this.handleChange }>
                        <option value="AstraZeneca">AstraZeneca</option>
                        <option value="Moderna">Moderna</option>
                        <option value="Sinopharm">Sinopharm</option>
                        <option value="Pfizer">Pfizer</option>
                        <option value="SputnikV">SputnikV</option>
                        <option value="Verocell">Verocell</option>

                    </select>
                </div>
                <div className="form-group col-xl-6">
                    <h3 className="text-left">{t('vaccine.injection-date')}</h3>
                    <input type="date" name="dt_injection_1" className="form-control"/>
                </div>
            </div> 
            <div className="form-group">
                <h3 className="text-left">{t('vaccine.image')}</h3>
                <input type="file" name="img_injection_1" className="form-control" onChange={this.onFileChange} />
            </div>
            <div className="row">
                
                <div className="form-group col-xl-6">
                    <h3 className="text-left">{t('vaccine.injection')} 2</h3>
                    <select className="form-control" name="cb_injection_2" onChange={ this.handleChange }>
                        <option value="AstraZeneca">AstraZeneca</option>
                        <option value="Moderna">Moderna</option>
                        <option value="Sinopharm">Sinopharm</option>
                        <option value="Pfizer">Pfizer</option>
                        <option value="Sputnikv">Sputnikv</option>
                        <option value="Verocell">Verocell</option>

                    </select>
                </div>
                <div className="form-group col-xl-6">
                    <h3 className="text-left">{t('vaccine.injection-date')}</h3>
                    <input type="date" name="dt_injection_2" className="form-control"/>
                </div>
            </div>
           
            
            <div className="form-group">
                <h3 className="text-left">{t('vaccine.image')}</h3>
                <input type="file" name="img_injection_2" className="form-control" onChange={this.onFileChange}/>
            </div>
            <div className="form-group">
                <Link to={ "/checkin/visitor-sign/" + this.props.match.params.customer_id }><button className="btn btn-light mr-4">{t('visitor.back')}</button></Link>
                <button type="submit" className="btn btn-success">{t('visitor.next')}</button>
            </div>
            </>

            
            elmForm = <>
                <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <form onSubmit = { (e) => this.handleSubmit(e) }>

                            { injection_vaccine }
                            </form>
                        </div>                    
                </div>
                
            </>
        }

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                            <div className="card-body px-lg-5 py-lg-5">
                                <div className="text-center">
                                    <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                    { this.renderVaccine() }
                                    { elmForm }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(Vaccine);