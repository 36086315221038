import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { Link, Redirect } from 'react-router-dom';


class QRContainer extends Component {
  state = {
    isRedirect: false
  }

  componentDidMount(){
    let company = JSON.parse(localStorage.getItem("company_data"));
    this.setState({
        company_logo : company.company_logo
    })
  }

  handleScan = data => {
    if (data) {
        let new_data = JSON.parse(data);
        let api_url = "https://www.qior.com.vn/api/v1/visitors/show/" + this.props.match.params.customer_id + "/" + new_data.visitor_id;
        fetch(api_url)
        .then(response => response.json())
        .then(result => {
            // console.log(result);
            localStorage.setItem('visitors_data', JSON.stringify(result));
            localStorage.setItem('qr-checkin', true);
            this.setState({
                isRedirect: true
            })
        });
        

    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    if(this.state.isRedirect)
    {
      let url = "/checkin/qr-code/visitor/" + this.props.match.params.customer_id;
      return <Redirect to={ url } />
    }
    return (
        <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                {/* {/* <div className="row"> */}
                                <QrReader
                                    facingMode = {'environment'}
                                    delay={300}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    style={{ width: '100%' }}
                                    />
                                {/* </div> */} 
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    )
  }
}
export default QRContainer
