import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="py-5" id="footer-main">
        <div className="container">
          <div className="row align-items-center justify-content-xl-between">
            <div className="col-xl-6">
              <div className="copyright text-center text-xl-left text-light">
                © {(new Date().getFullYear())}
                <a href="https://qi.com.vn" className="font-weight-bold ml-1 text-white" rel="noreferrer" target="_blank">Qi Technologies</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;