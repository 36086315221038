import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Employee extends Component {

    constructor(props){
        super(props);
        this.state = {
            employee_id : null,
            employees : [],

        };
    }

    handleChange = (event) => {
        this.props.parentCallback(event.target.value);
        this.setState({
            employee_id : event.target.value
        });
        localStorage.setItem('employee_id', event.target.value);
    }

    componentDidMount(){
        this.refreshList();
    }


    refreshList(){
        fetch('https://www.qior.com.vn/api/v1/employee/show/' + this.props.customer_id)
        .then(response => response.json())
        .then(data => {
            this.setState({ employees : data });
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                    <h3>{t('employee.title')}</h3>
                    <div className="form-group">
                        <select className="form-control" value={this.props.employee_id} onChange = {this.handleChange}>
                            <option value='default'>{t('employee.choose')}</option>
                            { this.state.employees.map( employee => {
                                if(this.props.employee_id === employee.id)
                                {
                                    localStorage.setItem('employee_id', employee.id);
                                    // this.props.parentCallback(employee.id);
                                }
                                    if(employee.customer_type === "building"){
                                        return <option key={ employee.id } value={ employee.id }>{ employee.company_name }</option>
                                    }else if(employee.customer_type === "company"){
                                        return <option key={ employee.id } value={ employee.id }>{ employee.first_name } { employee.last_name }</option>
                                    }      
                                }
                            )}
                        </select>
                    </div>
                </>
        );
    }
}

export default withTranslation()(Employee);