import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import Swal from 'sweetalert2';
import axios from 'axios';
import { withTranslation } from 'react-i18next';


class ThingCarrying extends Component {

    constructor(props){
        super(props);
        this.state = {
            hasThing : false,
            imageSrc : "",
            isCapture : false,
            isRedirect : false,
            isBlocking : false
        }
    }

    hasThingCarrying = () => {
        this.setState({
            hasThing : true
        });
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        })
    };

    retake = () => {
        this.setState({
            imageSrc : "",
            isCapture : false
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let data = {
            txt_description : event.target.txt_description.value,
            img_thing : this.state.imageSrc
        }
        let detail_id = localStorage.getItem('detail_id');
        let customer_id = this.props.match.params.customer_id;
        let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store-thing-carrying/" + customer_id + "/" + detail_id;
        let bodyFormData = new FormData();
        bodyFormData.append('txt_description', event.target.txt_description.value);
        bodyFormData.append('img_thing', this.state.imageSrc);
        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
            if(result.data.status_code === 200){
                event.target.reset();
                this.setState({
                  isBlocking : false,
                  isRedirect : true, 
                });
            }
            else if(result.data.status_code === 500)
            {
                return Swal.fire('Lỗi 500!', result.data.message , "error");
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
        // fetch(api_url, {
        //     method : 'POST',
        //     headers: {
        //     'Accept' : 'application/json',
        //     'Content-Type' : 'application/json',
        //     // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
        //     },
        //     body: JSON.stringify(data)
        // })
        // .then(response => response.json())
        // .then(result => {
        //     if(result.status_code === 200){
        //         event.target.reset();
        //         this.setState({
        //           isBlocking : false,
        //           isRedirect : true, 
        //         });
        //     }
        //     else if(result.status_code === 500)
        //     {
        //         return Swal.fire('Lỗi 500!', result.message , "error");
        //     }
        //   })
        //   .catch(error => {
        //     console.log(JSON.parse(error));
        // })

    }

    renderHasThing(){

        const { t } = this.props;

        let url = "/checkin/nda/" + this.props.match.params.customer_id;

        if(this.state.hasThing === false)
        {
            return <>
                    <div className="form-group">
                        <h1>{t('thing.title')}</h1>
                    </div>

                    <div className="form-group">
                        <button onClick={ this.hasThingCarrying } id="btn-yes" className="btn btn-success">{t('thing.yes')}</button>
                        <Link to={ url }><button id="btn-no" className="btn btn-danger">{t('thing.no')}</button></Link>
                    </div>
            </>
        }
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }


    render() {

        const { t } = this.props;

        if(this.state.isRedirect)
        {
            let url = "/checkin/nda/" + this.props.match.params.customer_id;
            return <Redirect to={ url } />
        }

        let elmForm = null;
        let takPho = null;
        let hasThing = this.state.hasThing;
        let isCapture = this.state.isCapture;

        const videoConstraints = {
            facingMode: { exact: "environment" }
        };

        if(hasThing === true)
        {
            if(isCapture === false)
            {
                takPho = <>
                    <div className="col-xl-12">
                        <Webcam width="100%" audio={false} ref={this.setRef} videoConstraints={videoConstraints} screenshotFormat="image/jpeg"/>
                        <button type="button" className="btn btn-primary" onClick={ this.capture}>{t('thing.capture-btn')}</button>
                    </div>
                    {/* <div className="form-group">
                    </div> */}
                </>
            }
            else{
                takPho = <>
                        <div className="col-xl-12">
                            <div className="form-group">
                                <TakePhoto imgsrc={ this.state.imageSrc }/>
                                <br/>
                                <button type="button" className="btn btn-info" onClick={this.retake}>{t('thing.retake')}</button>
                            </div>
                            
                            {/*  */}
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">{t('visitor.next')}</button>
                            </div>
                        </div>
                    </>
            }
            elmForm = <>
                <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <form onSubmit = { (e) => this.handleSubmit(e) }>

                                <div className="form-group">
                                    <h1>{t('thing.describe')}</h1>
                                    <input type="text" name="txt_description" className="form-control" placeholder={t('thing.placeholder')}/>
                                </div>
                                <div className="form-group">
                                    <h1>{t('thing.capture-des')}</h1>
                                    <div className="row">
                                        { takPho }
                                        
                                        {/* <div className="col-xl-12">
                                            {imgSrc && ( <img width="100%" src={ imgSrc }/> )}
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>                    
                </div>
                
            </>
        }

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card bg-secondary border-0 mb-0">
                                    <div className="card-body px-lg-5 py-lg-5">
                                        <div className="text-center">
                                            <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                            { this.renderHasThing() }
                                            { elmForm }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class TakePhoto extends Component {
    
    render() {
        const imgsrc = this.props.imgsrc;

        return (
            <div>
                <img width="100%" src={ imgsrc } alt=""/>
            </div>
        );
    }
}

// export default ThingCarrying;

// function ThingCarrying()
// {
//         const webcamRef = React.useRef(null);
//         const [imgSrc, setImgSrc] = React.useState(null);

//         const capture = React.useCallback(() => {
//             const imageSrc = webcamRef.current.getScreenshot();
//             setImgSrc(imageSrc);
//         }, [webcamRef, setImgSrc]);

//         const videoConstraints = {
//             facingMode: { exact: "environment" }
//           };
//         return (
            
//         );
// }


export default withTranslation()(ThingCarrying);