import { BrowserRouter as Router } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Home from '../Home/Home';
import RouterURL from '../RouterURL/RouterURL';
import RouterURLv1 from '../RouterURL/RouterURLv1';


function App() {
  return (
    <Router>
    <div className="main-content" id="panel">
      <Header/>
      {/* <RouterURL></RouterURL> */}
      <RouterURLv1></RouterURLv1>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
