import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import axios from 'axios';
import io from "socket.io-client";
import swal from 'sweetalert';


// let endPoint = "https://www.qior.com.vn"; // http://www.qior.com.vn:5002
// // let endPoint = "http://210.245.55.12:3006";
// let socket = io.connect(`${endPoint}`);


class Cam extends Component {
    constructor (props) {
        super(props);
        this.state = {
            imageSrc : "",
            company_logo : '',
            isCapture : false,
            isProcess : false,
            isRedirect : false,
            messages: ["Hello and Welcome"],
            message: ""
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        })
      };
    
    componentDidMount() {
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
        let customer_id = this.props.match.params.customer_id;
        this.timerID = setInterval(
            () => {
                let feat = this.webcam.getScreenshot();
                // socket.emit("message", { feat, customer_id});
                // socket.once("message", msg => {
                //     if(msg.status_code === 215)
                //     {
                //         this.setState({
                //             message : msg.message
                //         });
                //     }
                //     else{

                //         let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/checkout/" + customer_id;
                //         const url = "/" + customer_id;
                //         // redirect                        
                //         let bodyFormData = new FormData();
                        
                //         bodyFormData.append('visitor_id', msg.id);
                //         bodyFormData.append('detail_id', 'no-id');
                //         axios({
                //             method: "post",
                //             url: api_url,
                //             data: bodyFormData,
                //             headers: { "Content-Type": "multipart/form-data" },
                //         })
                //         .then(result => {
                //             if(result.status === 200){
                //                 this.setState({
                //                     isRedirect : true, 
                //                     message : ''
                //                 });
                //                 const audioEl = document.getElementsByClassName("audio-element")[0]
                //                 audioEl.play()
                //                 swal({
                //                     icon : "success",
                //                     title: "Success!",
                //                     text: "CẢM ƠN QUÝ KHÁCH '"+ msg.visitor_name +"'. XIN VUI LÒNG LIÊN HỆ QUẦY LỄ TÂN ĐỂ NHẬN LẠI GIẤY TỜ TÙY THÂN!",
                //                     type: "success",
                //                     timer: 3000,
                //                     showConfirmButton: false
                //                 }).then(function() {
                    
                //                     window.location.href = url;
                //                 });
                //             }else if(result.status === 205)
                //             {
                //                 this.setState({
                //                     message : ''
                //                 });
                //                 swal({
                //                     icon : "error",
                //                     title: "Lỗi 205!",
                //                     text: 'Không tìm thấy dữ liệu!!!',
                //                     type: "error",
                //                     timer: 3000,
                //                     showConfirmButton: false
                //                 })
                //             }
                //         })
                //         .catch(error => {
                //             console.log(JSON.parse(error));
                //         })
                //     }
                // });
            },
            1000
        );
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg- col-md-8 px-5">
                        <h1 className="text-white">Welcome to Qi VMS</h1>
                        <p className="text-lead text-white">We provide for you the new solution of Covid years. This solution help you  
                        managing the vistor check-in/out easily.
                        </p>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                                <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                <div className="row justify-content-center">
                                    <div className="col-xl-6">
                                        <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                                        <img width="50%" src={ this.state.imageSrc } alt=""/>
                                        <h1>{ this.state.message }</h1>
                                        <audio className="audio-element">
                                            <source src="/assets/sound-effect-GOODBYE.mp3" type="audio/mpeg"/>
                                        </audio>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Cam;