import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { SemipolarLoading } from 'react-loadingg';

const ws = new WebSocket("wss://face.pvndns.net/pub/facerecognize");

class SubmitData extends Component {

    constructor(props){
        super(props);
        this.state = {
            isRedirect : false,
            isBlocking : false
        }
    }

    async submit() {
        let visitor = JSON.parse(localStorage.getItem('visitor'));

        // get object meeting employee or company
        let employee_id = localStorage.getItem('employee_id');
        let company_id = localStorage.getItem('company_id');
        let department_id = localStorage.getItem('department_id');

        // Get visitor type
        let visitor_type = JSON.parse(localStorage.getItem('visitor_type'));

        // Get detail meeting
        let detail = JSON.parse(localStorage.getItem('detail'));

        // Get thing carrying
        let thing = JSON.parse(localStorage.getItem('thing_carrying'));
        let img = localStorage.getItem('img');

        // Get face image
        let face_img = localStorage.getItem('face_img');
        let image_id = localStorage.getItem('image_id');

        // Get meeting note
        let meeting_note = localStorage.getItem('meeting_note');

        // get nda image
        let nda_image = localStorage.getItem('nda_image');

        let bodyFormData = new FormData();

        // Fill data visitor to form
        bodyFormData.append('visitor_id', visitor.visitor_id === null ? '' : visitor.visitor_id);
        bodyFormData.append('txt_name', visitor.txt_name);
        bodyFormData.append('dt_birth', visitor.dt_birth);
        bodyFormData.append('txt_phone_number', visitor.txt_phone_number);
        bodyFormData.append('txt_email', visitor.txt_email);
        bodyFormData.append('txt_identity', visitor.txt_identity);
        bodyFormData.append('dt_issuedOn', visitor.dt_issuedOn);
        bodyFormData.append('txt_nationality', visitor.txt_nationality);
        bodyFormData.append('txt_company', visitor.txt_company !== null ? visitor.txt_company : '-');
        bodyFormData.append('txt_position', visitor.txt_position);
        bodyFormData.append('txt_address', visitor.txt_address);

        // Choose employee
        bodyFormData.append('cb_person', employee_id);
        bodyFormData.append('txt_note', meeting_note);
        // Choose visitor type
        bodyFormData.append('cb_purpose', visitor_type.id);
        // Choose detail meeting
        bodyFormData.append('dt_dateVisit', detail.dt_dateVisit);
        bodyFormData.append('dt_from', detail.dt_from);
        bodyFormData.append('dt_to', detail.dt_to);

        // Sign with face
        bodyFormData.append('img', img);
        // Thing carrying
        if(thing !== null)
        {
            bodyFormData.append('txt_description', thing.txt_description);
            bodyFormData.append('img_thing', thing.img_thing  === null ? '' : thing.img_thing);
        }

         // Signature       
        if(nda_image !== null)
        {
            bodyFormData.append('sign', nda_image);
        }
        



        let customer_id = this.props.match.params.customer_id;
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/store/" + customer_id;

        console.log(bodyFormData);
        await axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
                
            },
        })
        .then(result => {
            if(result.data.status_code === 200){

                let data =  {
                    "command":"visitor_mapping",
                    "userid": image_id, 
                    "visitorid" : result.data.visitor_id
                } 
                ws.send(JSON.stringify(data));
                ws.onmessage = function (event) {
                    let message = JSON.parse(event.data);
                    console.log(message.result);
                    if(message.result === 'true')
                    {
                        localStorage.setItem("face_signup", 'yes');
                    }
                }                
            }
            else if(result.data.status_code === 500)
            {
                return Swal.fire('Lỗi 500!', result.data.message , "error");
            }
        })
        .catch(error => {
            console.log(JSON.parse(error));
        })
    }

    completedSignMeeting = (url) => {
        this.interval = setInterval(() => {
            let face_signup = localStorage.getItem('face_signup');
            // console.log(face_signup);
            if(face_signup === 'yes')
            {
                this.setState({
                    isRedirect : true, 
                    isProcess : false,
                });
                localStorage.clear();
                swal({
                    icon : "success",
                    title: "Success!",
                    text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                    }).then(function() {
                    
                    window.location.href = url;
                });
            } 
        }, 1000);
        
    }

    componentDidMount() {
        
        ws.onopen = () => {
            console.log("connected websocket main component");
        };

        this.submit();

        const url = "/" + this.props.match.params.customer_id;

        this.completedSignMeeting(url);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default SubmitData;