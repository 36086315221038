import React, { Component } from 'react';
import {Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Checkin from '../Checkin/Checkin';
import TypeEmployee from '../TypeEmployee/TypeEmployee';
import VisitorSign from '../VisitorSign/VisitorSign';
import Vaccine from '../VisitorSign/Vaccine';
import MeetingSign from '../MeetingSign/MeetingSign';
import CapturePhoto from '../CapturePhoto/CapturePhoto';
import ThingCarrying from '../ThingCarrying/ThingCarrying';
import NDA from '../NDA/NDA';
import Module from '../Checkin/Module/Module';
import FaceRecognize from '../Checkin/Module/FaceRecognize/FaceRecognize';
import Visitor from '../Checkin/Module/Visitor';
import QRContainer from '../Checkin/Module/QRContainer/QRContainer';

// Checkout import js
import QROut from '../App/Checkout/Module/QROut';
import Cam from '../App/Checkout/Module/Cam';
import FaceRecognitionOut from '../App/Checkout/Module/FaceRecognitionOut';
import ModuleCheckout from '../App/Checkout/ModuleCheckout';
import VideoInput from '../VideoInput';
import FaceRecognizeRealTime from '../Checkin/Module/FaceRecognize/FaceRecognizeRealTime';
import VisitorNew from '../App/Checkin/NewRegister/VisitorNew';




class RouterURL extends Component {
    render() {
        return (
            <Switch>

                {/* <Route exact path="/" component={VideoInput}/> */}
                <Route exact path="/" component={VisitorNew}/>

                <Route exact path="/:customer_id" component={Home}/>
                <Route exact path="/checkin/:customer_id" component={Checkin}/>
                {/* Registered */}
                    <Route exact path="/checkin/module/:customer_id" component={Module}/>
                    <Route exact path="/checkin/face-recognize/:customer_id" component={FaceRecognize}/>
                    <Route exact path="/checkin/face-recognize-real-time/:customer_id" component={FaceRecognizeRealTime}/>

                    <Route exact path="/checkin/face-recognize/visitor/:customer_id" component={Visitor}/>

                    <Route exact path="/checkin/qr-code/:customer_id" component={QRContainer}/>
                    <Route exact path="/checkin/qr-code/visitor/:customer_id" component={Visitor}/>
                {/* {} */}
                <Route exact path="/checkin/type-employee/:customer_id" component={TypeEmployee}/>
                <Route exact path="/checkin/visitor-sign/:customer_id" component={VisitorSign}/>
                <Route exact path="/checkin/visitor-vaccinated/:customer_id" component={Vaccine}/>
                <Route exact path="/checkin/meeting-sign/:customer_id" component={MeetingSign}/>
                <Route exact path="/checkin/capture-photo/:customer_id" component={CapturePhoto}/>
                <Route exact path="/checkin/thing-carrying/:customer_id" component={ThingCarrying}/>
                <Route exact path="/checkin/nda/:customer_id" component={NDA}/>
                {/* Checkout */}
                <Route exact path="/checkout/module/:customer_id" component={ModuleCheckout}/>

                <Route exact path="/checkout-face/:customer_id" component={FaceRecognitionOut}/>
                <Route exact path="/checkout-qr/:customer_id" component={QROut}/>
                {/* {} */}
                
            </Switch>
        );
    }
}

export default RouterURL;