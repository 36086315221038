import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import axios from 'axios';
import { loadModels, getFullFaceDescription, extractFaceFromBox } from '../../../../../api/face';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';


// const ws = new WebSocket("wss://face.pvndns.net/pub/facerecognize");

const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 224;

class FaceCheckin_v2 extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);


        this.state = {
            imageSrc: "",
            isCapture: false,
            isProcess: false,
            isRedirect: false,
            isDetect: false,
            isInit : true,
            count : 0,

            fullDesc: null,
            detections: null,
            descriptors: null,
            faceMatcher: null,
            match: null,
            face : null,
            company_logo : "",
            face_img : "",
            name : "",
            ws : "",
            socket_api : null,
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
    };
   
    componentDidMount = async () => {
        let company = JSON.parse(localStorage.getItem("company_data"));
        let socket_api = localStorage.getItem("socket_api");

        localStorage.removeItem('face_id');
        localStorage.removeItem('image_id');
        localStorage.removeItem('img');
        localStorage.removeItem('visitor');
        localStorage.removeItem('module');
        localStorage.removeItem('face_unknown');


        this.setState({
            company_logo : company.company_logo,
            socket_api : socket_api
        });
        await loadModels();
        this.setInputDevice();

        this._isMounted = true;
        

    }

    setInputDevice = () => {
        navigator.mediaDevices.enumerateDevices().then(async devices => {
            let inputDevice = await devices.filter(
                device => device.kind === 'videoinput'
            );
            if (inputDevice.length < 2) {
                await this.setState({
                    facingMode: 'user'
                });
            } else {
                await this.setState({
                    facingMode: { exact: 'environment' }
                });
            }
            this.startCapture();
        });
    };

    startCapture = () => {
        this.interval = setInterval(() => {
            this.capture();
        }, 800);
    };

    capture = async () => {
        const name_id = { fullname: "", uuid: "" };
        const customer_id = this.props.match.params.customer_id;

        // console.log(this.webcam);
        if (!!this.webcam) {

            // Get face Detection Box
            await getFullFaceDescription(
                this.webcam.getScreenshot(),
                inputSize
            ).then(fullDesc => {
                if (!!fullDesc) {
                    this.setState({
                        isInit : false,
                        detections: fullDesc.map(fd => fd.detection),
                        descriptors: fullDesc.map(fd => fd.descriptor)
                    });
                    let detections = fullDesc.map(fd => fd.detection);
                    if (detections.length > 0) {
                        let data = { "command": "face_recognize", "image": this.webcam.getScreenshot() };
                        this.connect(data);
                        
                        // ws.send(JSON.stringify(data));
                        // ws.onmessage = function (event) {

                        //     let mes = JSON.parse(event.data);
                        //     let faces = mes.message;
                        //     if (Array.isArray(faces)) {
                        //         let visitor_id = faces.map((face) => face.visitors);
                        //         let image_id = faces.map((face) => face.userid);
                        //         localStorage.setItem('face_id', visitor_id[0]);
                        //         localStorage.setItem('image_id', image_id[0]);
                        //     }
                        //     else {
                        //         document.getElementById('message').innerHTML = faces;
                        //         localStorage.setItem('face_unknown', faces);
                        //     }

                        // };

                        
                        let face_id = localStorage.getItem('face_id');
                        if(face_id !== '' || face_id !== null)
                        {
                            let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/get-info/" + customer_id + "/" + face_id;

                            axios({
                                method: "get",
                                url: api_url,
                                headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
                            })
                            .then(async result => {
                                if(result.data.status === 200)
                                {
                                    localStorage.setItem('visitor', JSON.stringify(result.data.visitor));
                                    localStorage.setItem('img', this.webcam.getScreenshot());
                                    localStorage.setItem('module', 'face-recognize');
                                    this.setState({
                                        isRedirect : true,
                                    });
                                }
                                else if(result.data.status === 500)
                                {
                                    // return Swal.fire('Lỗi 500!', data.visitor , "error");
                                    let url_err = "/checkin/visitor-sign/" + this.props.match.params.customer_id;
                                    window.location.href = url_err;
                                }
                            });

                        }

                        let face_unknown = localStorage.getItem('face_unknown');
                        if(face_unknown === 'Đang nhận dạng...')
                        {
                            let count = this.state.count + 1;
                            this.setState({
                                count : count,
                            });
                            if(this.state.count >= 4)
                            {
                                let url_err = "/checkin/visitor-sign/" + this.props.match.params.customer_id;
                                window.location.href = url_err;
                            }
                        }
                        
                    }


                }
            });


            if (!!this.state.descriptors && !!this.state.faceMatcher) {
                let match = await this.state.descriptors.map(descriptor =>
                    this.state.faceMatcher.findBestMatch(descriptor)
                );
                this.setState({ match });
            }
        }
    };


    componentWillUnmount() {
        // ws.close();
        
        this.setState({
            ws : null,
        });
        this._isMounted = false;
        clearInterval(this.interval);
    }


    connect = (data) => {
        let that = this; // cache the this
        var connectInterval;
        var ws = new WebSocket(this.state.socket_api);

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");

            ws.send(JSON.stringify(data));

            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };
        // console.log(JSON.stringify(data));

        ws.onmessage = function (event) {
            let mes = JSON.parse(event.data);
            let faces = mes.message;
            if (Array.isArray(faces)) {
                let visitor_id = faces.map((face) => face.visitors);
                let image_id = faces.map((face) => face.userid);
                localStorage.setItem('face_id', visitor_id[0]);
                localStorage.setItem('image_id', image_id[0]);
            }
            else {
                document.getElementById('message').innerHTML = 'Đang nhận dạng...';
                localStorage.setItem('face_unknown', 'Đang nhận dạng...');
            }
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
     check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    render() {

        if(this.state.isRedirect)
        {
            // ws.close();

            let url = "/checkin/face-recognize/visitor/" + this.props.match.params.customer_id;
            // return <Redirect to={ url } />
            window.location.href = url;
        }

        const { detections, match, face } = this.state;
        let videoConstraints = null;
        videoConstraints = {
            width: WIDTH,
            facingMode: 'user'
        };

        let drawBox = null;
        if (!!detections) {

            drawBox = detections.map((detection, i) => {
                let _H = detection.box.height;
                let _W = detection.box.width;
                let _X = detection.box._x;
                let _Y = detection.box._y;

                return (
                    <div key={i}>
                        <div
                            style={{
                                border: 'solid',
                                borderColor: 'green',
                                height: _H,
                                width: _W,
                                transform: `translate(${_X}px,${_Y}px)`
                            }}
                        >
                            <p id="message" style={{ fontWeight: 'bold',color:'green' }}></p>
                            {!!match && !!match[i] ? (
                                <p
                                    style={{
                                        backgroundColor: 'green',
                                        border: 'solid',
                                        borderColor: 'green',
                                        marginTop: 0,
                                        color: '#fff',
                                        transform: `translate(-3px,${_H}px)`
                                    }}
                                >
                                    {match[i]._label}
                                </p>
                            ) : null}
                        </div>
                    </div>
                );
            });
        }

        return (

            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card bg-secondary border-0 mb-0">
                                    {/* <img src={this.state.face_img}/> */}
                                    <div className="card-body px-lg-5 py-lg-5">
                                        <div className="text-center">
                                            <img style={{ marginTop: "4px", marginBottom: "4px" }} width="200px" height="200px" src={ this.state.company_logo } alt="" /><br />
                                            <div className="row justify-content-center">
                                                <div className="col-xl-6">
                                                    <div
                                                        className="Camera"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: HEIGHT
                                                            }}
                                                        >
                                                            <div>
                                                                { this.state.isInit && <p>Đang khởi tạo. Vui lòng đợi...</p> }
                                                                <h3 className="text-warning">Vui lòng tháo khẩu trang!</h3>
                                                                {!!videoConstraints ? (
                                                                    <div style={{ position: 'absolute' }}>
                                                                        <Webcam
                                                                            audio={false}
                                                                            width="100%"
                                                                            ref={this.setRef}
                                                                            screenshotFormat="image/jpeg"
                                                                            videoConstraints={videoConstraints}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                                {!!drawBox ? drawBox : null}
                                                                { this.state.isInit && <SemipolarLoading color={'#33D7FF'}/> }
                                                            </div>
                                                            {/* <img src={this.state.face}/> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default FaceCheckin_v2;