import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';

class QROut extends Component {
  state = {
    isRedirect: false,
    isPlaying : false,
    device_code : null,
  }

  handleScan = data => {
    if (data) {
        // console.log(data);
        // let new_data = {detail_id : JSON.parse(data).visitor_detail_id};
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/checkout/" + this.props.match.params.customer_id;

        // // redirect
        const url = "/" + this.props.match.params.customer_id + "/" + this.state.device_code;
        
        let bodyFormData = new FormData();
        bodyFormData.append('code', data);

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data",
                      "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
                     },
        })
        .then(result => {
            if(result.status === 200){
                this.setState({
                    isRedirect : true, 
                });
                const audioEl = document.getElementsByClassName("audio-element")[0]
                audioEl.play()
                swal({
                    icon : "success",
                    title: "Success!",
                    text: "Bạn đã checkout thành công!",
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                  }).then(function() {
                    
                    window.location.href = url;
                });
            }
            else if(result.status === 205)
            {
                return Swal.fire('Lỗi 205!', "QR đã hết hạn!!!" , "error");
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })

    }
  }
  handleError = err => {
    console.error(err)
  }

  componentDidMount(){
    let company = JSON.parse(localStorage.getItem("company_data"));
    let device_code = localStorage.getItem("device_id");
    this.setState({
        company_logo : company.company_logo,
        device_code : device_code,
    })
  }

  render() {
    return (
        <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                {/* {/* <div className="row"> */}
                                <div className="row justify-content-center">
                                    <div className="col-xl-6">
                                      <QrReader
                                      facingMode = {'environment'}
                                      delay={300}
                                      onError={this.handleError}
                                      onScan={this.handleScan}
                                      style={{ width: '100%' }}
                                      />
                                      {/* </div> */}
                                      <audio className="audio-element">
                                        <source src="/assets/sound-effect-GOODBYE.mp3" type="audio/mpeg"/>
                                      </audio>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    )
  }
}
export default QROut
