import React, { Component } from 'react';
import { Link, Redirect, Prompt } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios';
import { withTranslation } from 'react-i18next';

class MeetingSign extends Component {

    constructor(props){
      super(props);
      this.state = {
        isRedirect : false,
        isBlocking : false,
        isCheckin : false
      };
    }

    handleChange = (event) => {
      this.setState({
        [event.target.name] : event.target.value,
        isBlocking : event.target.value.length > 0
      });
    }

    storeImg(visitor_id, detail_id, customer_id, imgsrc){    
        let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store-img-visitor-detail/" + customer_id + "/" + visitor_id + "/" + detail_id;

        let bodyFormData = new FormData();
        bodyFormData.append('img', imgsrc);

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
            if(result.data.status_code === 200){
              this.setState({
                isCheckin : true
              });
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })

        // fetch(api_url, {
        //     method : 'POST',
        //     headers: {
        //     'Accept' : 'application/json',
        //     'Content-Type' : 'application/json',
        //     // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
        //     },
        //     body: JSON.stringify({img: imgsrc})
        // })
        // .then(response => response.json())
        // .then(result => {
        //     if(result.status_code === 200){
        //       this.setState({
        //         isCheckin : true
        //       });
        //     }
        //   })
        //   .catch(error => {
        //     console.log(JSON.parse(error));
        // })
    }

    handleSubmit = (event) => {
      event.preventDefault();

      let data = {
        dt_dateVisit : event.target.dt_dateVisit.value,
        dt_from : event.target.dt_from.value,
        dt_to : event.target.dt_from.value
      }
      let visitor_type_id = localStorage.getItem('visitor_type_id');
      let employee_id = localStorage.getItem('employee_id');
      let visitor_id = localStorage.getItem('visitor_id');

      let api_url = 'https://www.qior.com.vn/api/v1/visitor_detail/storeDetail/' + this.props.match.params.customer_id + '/' + visitor_id + '/' + employee_id + '/' + visitor_type_id;
      let bodyFormData = new FormData();
      bodyFormData.append('dt_dateVisit', event.target.dt_dateVisit.value);
      bodyFormData.append('dt_from', event.target.dt_from.value);
      bodyFormData.append('dt_to', event.target.dt_to.value);

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
          if(result.data.status_code === 200){
                localStorage.setItem('detail_id', result.data.visitor_detail_uuid);
                // Đăng ký gặp bằng ảnh khi visitor đã đăng ký
                if(localStorage.getItem("img") !== null) {
                  let imgsrc = localStorage.getItem("img");
                  this.storeImg(visitor_id, result.data.visitor_detail_uuid, this.props.match.params.customer_id, imgsrc);
                }
                else{
                  event.target.reset();
                  this.setState({
                    isBlocking : false,
                    isRedirect : true, 
                  });
                }
            }
            else if(result.data.status_code === 500){
                return Swal.fire('Lỗi!', result.data.error , "error");
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })

      // fetch(api_url, {
      //   method : 'POST',
      //   headers: {
      //     'Accept' : 'application/json',
      //     'Content-Type' : 'application/json',
      //     // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
      //   },
      //   body: JSON.stringify(data)
      // })
      // .then(response => response.json())
      // .then(result => {
      //   if(result.status_code === 200){
      //       localStorage.setItem('detail_id', result.visitor_detail_uuid);
      //       // Đăng ký gặp bằng ảnh khi visitor đã đăng ký
      //       if(localStorage.getItem("img") !== null) {
      //         let imgsrc = localStorage.getItem("img");
      //         this.storeImg(visitor_id, result.visitor_detail_uuid, this.props.match.params.customer_id, imgsrc);
      //       }
      //       else{
      //         event.target.reset();
      //         this.setState({
      //           isBlocking : false,
      //           isRedirect : true, 
      //         });
      //       }
      //   }
      //   else if(result.status_code === 500){
      //       return Swal.fire('Lỗi!', result.error , "error");
      //   }
      // })
      // .catch(error => {
      //   console.log(JSON.parse(error));
      // })
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }

    render() {
        const { t } = this.props;

        if(this.state.isRedirect)
        {
            let url = "/checkin/capture-photo/" + this.props.match.params.customer_id;
            return <Redirect to={ url } />         
        }
        if(this.state.isCheckin)
        {
            let url = "/checkin/thing-carrying/" + this.props.match.params.customer_id;
            return <Redirect to={ url } />
        }
        return (
            <div>
            <Prompt when={this.state.isBlocking} message={ location => (`Bạn có chắc muốn chuyển trang ${location.pathname}. Việc thực hiện thao tác này khiến cho dữ liệu bạn đang điền trong form sẽ bị mất?` ) } />
            {/* Header */}
            <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="card bg-secondary border-0 mb-0">
                      <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center">
                          <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />    
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <form onSubmit = { (event) => this.handleSubmit(event) }>
                              
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <label>{t('detail.date')} <span style={{color: 'red'}}>*</span></label>
                                            <input className="form-control" 
                                                  type="date" 
                                                  name="dt_dateVisit" 
                                                  onChange = { this.handleChange.bind(this) }/>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <label>{t('detail.time')} <b>{t('detail.from')}:</b> <span style={{color: 'red'}}>*</span></label>
                                            <input className="form-control" type="time" name="dt_from" onChange = { this.handleChange.bind(this) }/>
                                        </div>
                                        <div className="col-xl-6">
                                            <label>{t('detail.time')} <b>{t('detail.to')}:</b><span style={{color: 'red'}}>*</span></label>
                                            <input className="form-control" type="time" name="dt_to" onChange = { this.handleChange.bind(this) }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col-xl-6 form-group">
                                      <Link to={"/checkin/type-employee/" + this.props.match.params.customer_id}><button type="button" className="btn btn-light form-control">{t('detail.back')}</button></Link>
                                    </div>
                                    <div className="col-xl-6 form-group">
                                      <button type="submit" className="btn btn-primary form-control">{t('detail.next')}</button>
                                    </div>
                                </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withTranslation()(MeetingSign);