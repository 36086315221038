import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';
import { withTranslation } from 'react-i18next';



class NDA extends Component {
    constructor(props){
        super(props);
    }
    state = {trimmedDataURL: null, isRedirect : false}
    sigPad = {}
    clear = () => {
        this.sigPad.clear();
        this.setState({trimmedDataURL: null });
    }
    trim = () => {
        this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png')})
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if(this.state.trimmedDataURL !== null)
        {
            this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')})
        }

        const url = "/" + this.props.match.params.customer_id;
     
        if(this.state.trimmedDataURL === null)
        {
            return Swal.fire('Thông báo!', "Vui lòng ký tên để hoàn tất thủ tục" , "info");
        }
        else{
            let data = {
                sign : this.state.trimmedDataURL
            }
            let detail_id = localStorage.getItem('detail_id');
            let customer_id = this.props.match.params.customer_id;
            let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store_nda/" + customer_id + "/" + detail_id;

            let bodyFormData = new FormData();
            bodyFormData.append('sign', this.state.trimmedDataURL);
            axios({
                method: "post",
                url: api_url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(result => {
                if(result.data.status_code === 200){
                    event.target.reset();
                    this.setState({
                    isRedirect : true, 
                    });
                    localStorage.clear();
                    swal({
                        icon : "success",
                        title: "Success!",
                        text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                        type: "success",
                        timer: 2000,
                        showConfirmButton: false
                      }).then(function() {
            
                        window.location.href = url;
                    });
                }
                else if(result.data.status_code === 500)
                {
                    return Swal.fire('Lỗi 500!', result.data.message , "error");
                }
            })
            .catch(error => {
                console.log(JSON.parse(error));
            })

            // fetch(api_url, {
            //     method : 'POST',
            //     headers: {
            //     'Accept' : 'application/json',
            //     'Content-Type' : 'application/json',
            //     // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
            //     },
            //     body: JSON.stringify(data)
            // })
            // .then(response => response.json())
            // .then(result => {
            //     if(result.status_code === 200){
            //         event.target.reset();
            //         this.setState({
            //         isRedirect : true, 
            //         });
            //         localStorage.clear();
            //         swal({
            //             icon : "success",
            //             title: "Success!",
            //             text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
            //             type: "success",
            //             timer: 2000,
            //             showConfirmButton: false
            //           }).then(function() {
            
            //             window.location.href = url;
            //         });
            //     }
            //     else if(result.status_code === 500)
            //     {
            //         return Swal.fire('Lỗi 500!', result.message , "error");
            //     }
            // })
            // .catch(error => {
            //     console.log(JSON.parse(error));
            // })
        }

        
    }
    
    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="card bg-secondary border-0 mb-0">
                                <div className="card-body px-lg-5 py-lg-5">
                                    <div className="text-center">
                                        <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                        <div className="form-group">
                                            <h1>{t('nda.title')}</h1>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-xl-6">
                                                <form onSubmit= { (e) => this.handleSubmit(e) }>
                                                    <h1>{t('nda.sign')}</h1>
                                                    <div className="form-group" style={{border: '1px solid black'}}>
                                                        <SignatureCanvas penColor='black'
                                                            canvasProps={{ width: 500, height: 300, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }}/>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <button type="button" onClick={this.trim} className="btn btn-danger">{t('nda.keep-btn')}</button>
                                                        <button type="button" onClick={this.clear} className="btn btn-light">{t('nda.clear')}</button>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary">{t('nda.complete')}</button>
                                                </form>                                                
                                            </div>
                                            
                                        </div>
                                        {/* <br/>
                                        <div className="form-group">
                                            <Link to="/checkin/meeting-sign"><button type="submit" className="btn btn-primary col-xl-3">Complete</button></Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default withTranslation()(NDA);