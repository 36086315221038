import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import axios from 'axios';
import { loadModels, getFullFaceDescription,extractFaceFromBox } from '../../../../api/face';


const inputSize = 224;
// const ws = new WebSocket("wss://face.pvndns.net/pub/facerecognize");


class FaceCapture extends Component {
    constructor (props) {
        super(props);
        this.state = {
            imageSrc : "",
            isCapture : false,
            isProcess : false,
            isRedirect : false,
            isDetect : false,
            company_logo : "",
            ws : "",
            socket_api : null
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        })
      };
    
    retake = () => {
        this.setState({
            imageSrc : "",
            isCapture : false,
            qr_code : false
        })
      };
    
    useImg = async () => {

        this.setState({
            isProcess : true
        });

        await getFullFaceDescription(
            this.state.imageSrc,
            inputSize
          ).then(fullDesc => {
            if (!!fullDesc) {
                // Extract face from webcam
                let detections = fullDesc.map(fd => fd.detection);
                if(detections.length > 0)
                {
                    extractFaceFromBox(this.state.imageSrc, detections[0].box)
                    .then(result => {

                        localStorage.setItem('img', this.state.imageSrc);
                        localStorage.setItem('face_img', result);

                        this.setState({
                            isProcess : false,
                            isDetect : true
                        });

                        let visitor = JSON.parse(localStorage.getItem('visitor'));

                        let data =  {"command": "face_register", "image":  this.state.imageSrc, "fullname": visitor.txt_name }; 
                        this.connect(data);


                        // ws.onclose = function (event) {
                        //     ws.onopen = () => {
                        //         console.log("websocket reconnected");
                        //     };
                        // }
                        // ws.send(JSON.stringify(data));
                        // ws.onmessage = function (event) {

                        //     let mes = JSON.parse(event.data);
                        //     let faces = mes.message;
                        //     console.log(mes);
                        //     if(Array.isArray(faces))
                        //     {
                        //         let userid = faces.map((face) => face.userid);
                        //         localStorage.setItem('image_id', userid);
                        //     }
                    
                        // };

                        return Swal.fire({
                            icon: 'success',
                            title: "Phát hiện khuôn mặt",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
                }
                else
                {
                    this.setState({
                        isProcess : false,
                        isDetect : false
                    });
                    return Swal.fire('Lỗi 215!', "Không tìm thấy khuôn mặt xin vui lòng thử lại!" , "error");
                }
                    
            }
        });

        
    //     let bodyFormData = new FormData();
    //     bodyFormData.append('img', this.state.imageSrc);

    //     let api_url = "https://www.qior.com.vn/signimg/dectect-face";
        
    //     axios({
    //         method: "post",
    //         url: api_url,
    //         data: bodyFormData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     })
    //     // .then(response => response.json())
    //     .then(result => {
    //         if(result.data.status_code === 200){
    //             localStorage.setItem('img', this.state.imageSrc);

    //             this.setState({
    //               isProcess : false,
    //               isDetect : true
    //             });
    //             return Swal.fire({
    //                 icon: 'success',
    //                 title: result.data.message,
    //                 showConfirmButton: false,
    //                 timer: 1500
    //               })
    //         }
    //         else if(result.data.status_code === 215)
    //         {
    //             this.setState({
    //                 isProcess : false,
    //                 isDetect : false
    //             });
    //             return Swal.fire('Lỗi 215!', result.data.message , "error");
    //         }
    //       })
    //       .catch(error => {
    //         console.log(JSON.parse(error));
    //     })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            isBlocking : false,
            isRedirect : true, 
            isProcess : false
          });
    }

    componentDidMount = async () => {
        await loadModels();
        let company = JSON.parse(localStorage.getItem("company_data"));
        let socket_api = localStorage.getItem("socket_api");
        this.setState({
            company_logo : company.company_logo,
            socket_api : socket_api,
        })
    }

    componentWillUnmount(){
        // ws.close();
    }

    connect = (data) => {
        let that = this; // cache the this
        var connectInterval;
        var ws = new WebSocket(this.state.socket_api);

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");

            ws.send(JSON.stringify(data));

            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        ws.onmessage = function (event) {
            let mes = JSON.parse(event.data);
            let faces = mes.message;
            console.log(mes);
            if(Array.isArray(faces))
            {
                let userid = faces.map((face) => face.userid);
                localStorage.setItem('image_id', userid);
            }
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
     check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };
    

    render() {

        if(this.state.isRedirect)
        {
          let url = "/checkin/thing-carrying/" + this.props.match.params.customer_id;
        //   return <Redirect to={ url } />
          window.location.href = url;
        }

        let isCapture = this.state.isCapture;
        let elmForm = null;
        if(isCapture === true)
        {
            // elmForm = <TakePhoto imgsrc={ this.state.imageSrc }/>;
            elmForm = <>
                    <div className="row justify-content-center">
                        <form onSubmit={ (e) => this.handleSubmit (e) }>
                            <div className="form-group">
                                <TakePhoto imgsrc={ this.state.imageSrc }/>
                                <input type="hidden" name="img" value={ this.state.imageSrc }/>
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-info" onClick={this.retake}>Retake</button>
                                {!this.state.qr_code && <button type="button" className="btn btn-success" onClick={ this.useImg }>Use</button>}
                            </div>                    
                            {this.state.isDetect && <button type="submit" className="btn btn-primary">NEXT STEP</button>}
                        </form>
                    </div>


            </>
        }
        else{
            elmForm = <>
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <h3 className="text-warning">Vui lòng tháo khẩu trang!</h3>
                                <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Capture</button>
                        </div>
                    </>

        }
        

        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />

                                    { elmForm }
                                    { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }
                                    
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


class TakePhoto extends Component {
    
    render() {
    
        const imgsrc = this.props.imgsrc;
        return (
          <div>
            
            <img width="100%" src={ imgsrc } alt=""/>


          </div>
        );
      }
}


export default FaceCapture;