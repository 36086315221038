import React, { Fragment, Component } from 'react';
import { Link, Redirect, Prompt } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { TimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import LuxonUtils from '@date-io/luxon';
import { alpha } from '@material-ui/core/styles';
// const ws = new WebSocket("wss://face.pvndns.net/pub/facerecognize");

class Meeting extends Component {

    constructor(props){
      super(props);
      this.state = {
        dateMeetingValidate : [],
        timeFromValidate : [],
        timeToValidate : [],
        date : null,
        time_from : null,
        time_to : null,
        isRedirect : false,
        isBlocking : false,
        isCheckin : false,
        module : '',
        visitor_type : [],
        device_code : null,
        ws : null,
        socket_api : null,
        // Check module checkin, new or regitered

      };
    }

    handleValidation = (bodyFormData, event) => {

      let api_url = "https://www.qior.com.vn/api/v2/qiosk/validate-meeting";

      axios({
          method: "post",
          url: api_url,
          data: bodyFormData,
          headers: { 
            "Content-Type": "multipart/form-data",
            "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
          },
      })
      .then(result => {
        if(result.data.status === 200){
              // var dateOfVisit = new Date(this.state.date);

              let data = {
                'dt_dateVisit' : this.state.date,
                'dt_from' : this.format_time(this.state.time_from),
                'dt_to' : this.format_time(this.state.time_to),
              };
              
              localStorage.setItem('detail', JSON.stringify(data));

              let module_checkin = localStorage.getItem('module');
              // console.log(module_checkin);

              this.setState({
                isBlocking : false,
                isRedirect : true, 
                module : module_checkin
              });
          }
          else if(result.data.status === 404){
              let errors = result.data.error;
              // console.log(errors);

              this.setState({
                  dateMeetingValidate : !!errors.dt_dateVisit ? errors.dt_dateVisit : [],
                  timeFromValidate : !!errors.dt_from ? errors.dt_from : [],
                  timeToValidate : !!errors.dt_to ? errors.dt_to : [],
              });
          }
        })
        .catch(error => {
          console.log(JSON.parse(error));
      })
  }

    handleChange = (event) => {
      this.setState({
        [event.target.name] : event.target.value,
        isBlocking : event.target.value.length > 0
      });
    }

    storeImg(visitor_id, detail_id, customer_id, imgsrc){    
        let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store-img-visitor-detail/" + customer_id + "/" + visitor_id + "/" + detail_id;

        let bodyFormData = new FormData();
        bodyFormData.append('img', imgsrc);

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
            if(result.data.status_code === 200){
              this.setState({
                isCheckin : true
              });
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
    }


    // Process and format time =========
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    format_time(time)
    {
      var new_time = new Date(time);
      var h = this.checkTime(new_time.getHours());
      var m = this.checkTime(new_time.getMinutes());
      return h + ":" + m;
    }

    ////////////////////////
    

    handleSubmit = (event) => {
      
      event.preventDefault();
      
      // console.log(this.state.date);

      let bodyFormData = new FormData();
      // let form_meeting = {
      //   'dt_dateVisit' : event.target.dt_dateVisit.value,
      //   'dt_from' : event.target.dt_from.value,
      //   'dt_to' : event.target.dt_to.value,
      // };

      let date = '';
      let time_from = '';
      let time_to = '';
      if(this.state.date !== null)
      {
        var dateOfVisit = new Date(this.state.date);
        date = dateOfVisit.toLocaleDateString();
      }

      if(this.state.time_from !== null)
      {
        time_from = this.format_time(this.state.time_from);
      }

      if(this.state.time_to !== null)
      {
        time_to = this.format_time(this.state.time_to);
      }

      bodyFormData.append('dt_dateVisit', date);
      bodyFormData.append('dt_from', time_from);
      bodyFormData.append('dt_to', time_to);

      this.handleValidation(bodyFormData, event);

      if(this.state.visitor_type.isActive_things_carry !== 1 && this.state.visitor_type.isActive_nda !== 1)
      {
        this.submit();  
      }
      
    }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = (socket_api) => {
      var ws = new WebSocket(socket_api);
      let that = this; // cache the this
      var connectInterval;

      // websocket onopen event listener
      ws.onopen = () => {
          console.log("Websocket connected");

          this.setState({ ws: ws });

          that.timeout = 250; // reset timer to 250 on open of websocket connection 
          clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      };

      // websocket onclose event listener
      ws.onclose = e => {
          console.log(
              `Socket is closed. Reconnect will be attempted in ${Math.min(
                  10000 / 1000,
                  (that.timeout + that.timeout) / 1000
              )} second.`,
              e.reason
          );

          that.timeout = that.timeout + that.timeout; //increment retry interval
          connectInterval = setTimeout(this.check(socket_api), Math.min(10000, that.timeout)); //call check function after timeout
      };

      // websocket onerror event listener
      ws.onerror = err => {
          console.error(
              "Socket encountered error: ",
              err.message,
              "Closing socket"
          );

          ws.close();
      };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = (socket_api) => {
      const { ws } = this.state;
      if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(socket_api); //check if websocket instance is closed, if so call `connect` function.
  };

  returnToHome = () => {

      this.interval = setInterval(() => {
          let redirect = localStorage.getItem("redirect");
          let url_home = localStorage.getItem("url_home");
          if(redirect === "home")
          {
              // console.log(redirect);
              this.setState({
                  isRedirect : true, 
                  isProcess : false,
              });
              localStorage.clear();
              swal({
                  icon : "success",
                  title: "Success!",
                  text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                  type: "success",
                  timer: 2000,
                  showConfirmButton: false
                  }).then(function() {
                  
                  window.location.href = url_home;
              });
          }
      }, 1000);

      
  };


  async submit() {
      let visitor = JSON.parse(localStorage.getItem('visitor'));

      // get object meeting employee or company
      let employee_id = localStorage.getItem('employee_id');
      let company_id = localStorage.getItem('company_id');
      let department_id = localStorage.getItem('department_id');
      let device_id = localStorage.getItem('device_id');

      // Get visitor type
      let visitor_type = JSON.parse(localStorage.getItem('visitor_type'));

      // Get detail meeting
      let detail = JSON.parse(localStorage.getItem('detail'));

      // Get thing carrying
      let thing = JSON.parse(localStorage.getItem('thing_carrying'));
      let img = localStorage.getItem('img');

      // Get face image
      let face_img = localStorage.getItem('face_img');
      let image_id = localStorage.getItem('image_id');

      // Get meeting note
      let meeting_note = localStorage.getItem('meeting_note');

      let bodyFormData = new FormData();

      bodyFormData.append('device_id', device_id);
      // Fill data visitor to form
      bodyFormData.append('visitor_id', visitor.visitor_id === null ? '' : visitor.visitor_id);
      bodyFormData.append('txt_name', visitor.txt_name);
      bodyFormData.append('dt_birth', visitor.dt_birth);
      bodyFormData.append('txt_phone_number', visitor.txt_phone_number);
      bodyFormData.append('txt_email', visitor.txt_email);
      bodyFormData.append('txt_identity', visitor.txt_identity);
      bodyFormData.append('dt_issuedOn', visitor.dt_issuedOn);
      bodyFormData.append('txt_nationality', visitor.txt_nationality);
      bodyFormData.append('txt_company', visitor.txt_company !== null ? visitor.txt_company : '-');
      bodyFormData.append('txt_position', visitor.txt_position);
      bodyFormData.append('txt_address', visitor.txt_address);

      // Choose employee
      
      bodyFormData.append('txt_note', meeting_note);
      // Choose visitor type
      bodyFormData.append('cb_purpose', visitor_type.id);
      // Choose detail meeting
      bodyFormData.append('dt_dateVisit', detail.dt_dateVisit);
      bodyFormData.append('dt_from', detail.dt_from);
      bodyFormData.append('dt_to', detail.dt_to);

      bodyFormData.append('cb_person', employee_id === null ? '' : employee_id);
      bodyFormData.append('cb_company', company_id === null ? '' : company_id);
      bodyFormData.append('cb_department', department_id === null ? '' : department_id);
      // Sign with face
      bodyFormData.append('img', img);
      bodyFormData.append('platform', "web");
      // Thing carrying
      if(thing !== null)
      {
          bodyFormData.append('txt_description', thing.txt_description);
          bodyFormData.append('img_thing', thing.img_thing  === null ? '' : thing.img_thing);
      }

       // Signature       
      if(this.state.trimmedDataURL !== null)
      {
          bodyFormData.append('sign', this.state.trimmedDataURL);
      }
      



      let customer_id = this.props.match.params.customer_id;
      let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/store/" + customer_id;

      await axios({
          method: "post",
          url: api_url,
          data: bodyFormData,
          headers: { 
              "Content-Type": "multipart/form-data",
              "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
          },
      })
      .then(result => {
          // console.log(result.data);
          if(result.data.status_code === 200){
              
              let data =  {
                  "command":"visitor_mapping",
                  "userid": image_id, 
                  "visitorid" : result.data.visitor_id
              };
              
              let url_home = "/" + this.props.match.params.customer_id + "/" + this.state.device_code

              localStorage.setItem('face_signup',  JSON.stringify(data));
              localStorage.setItem('url_home',  url_home);
              // this.valid_visitor_mapping();
          }
          else if(result.data.status_code === 500)
          {
              return Swal.fire('Lỗi 500!', result.data.message , "error");
          }
      })
      .catch(error => {
          console.log(JSON.parse(error));
      });

      if(image_id !== null)
      {
          let face_signup = JSON.parse(localStorage.getItem('face_signup'));
          let ws = this.state.ws;
          ws.send(JSON.stringify(face_signup));
          ws.onmessage = function (event) {
              let message = JSON.parse(event.data);
              if(message.result === true)
              {
                  localStorage.setItem("redirect", "home");
              }
          }
      }
      else
      {
          localStorage.setItem("redirect", "home");
      }

  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.setState({
      ws : null,
    });
  }


    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        let visitor_type = JSON.parse(localStorage.getItem("visitor_type"));
        let device_code = localStorage.getItem("device_id");
        let socket_api = localStorage.getItem("socket_api");

        this.setState({
            company_logo : company.company_logo,
            visitor_type : visitor_type,
            device_code : device_code,
            socket_api : socket_api,
        });

        this.connect(socket_api);  
        this.returnToHome(); 
    }


    handleDateChange(date){
      // console.log(date);
      this.setState({
        date : date
      });

      
      // console.log(date1);
    }

    handleTimeChange(time){
      this.setState({
        time_from : time
      });
    }

    handleTimeToChange(time){
      this.setState({
        time_to : time
      });
    }
  

    render() {
        const { t } = this.props;

        if(this.state.isRedirect)
        {
          if(this.state.module === 'qr')
          {
            let url = "/checkin/capture-photo/" + this.props.match.params.customer_id;
            // return <Redirect to={ url } /> 
            window.location.href = url;
          }
          if(this.state.module === 'face-recognize')
          {
            if(this.state.visitor_type.isActive_things_carry === 1)
            {
                let url = "/checkin/thing-carrying/" + this.props.match.params.customer_id;
                // return <Redirect to={ url } />
                window.location.href = url;
            }
            else if(this.state.visitor_type.isActive_nda === 1)
            {
                let url = "/checkin/nda/" + this.props.match.params.customer_id;
                // return <Redirect to={ url } />
                window.location.href = url;
            }
          
            
          }
          if(this.state.module === 'new')
          {
            let url = "/checkin/face-capture/" + this.props.match.params.customer_id;
            // return <Redirect to={ url } />
            window.location.href = url;
          }
                  
        }
        
        return (
            <div>
            <Prompt when={this.state.isBlocking} message={ location => (`Bạn có chắc muốn chuyển trang ${location.pathname}. Việc thực hiện thao tác này khiến cho dữ liệu bạn đang điền trong form sẽ bị mất?` ) } />
            {/* Header */}
            <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="card bg-secondary border-0 mb-0">
                      <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center">
                          <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />    
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <form onSubmit = { (event) => this.handleSubmit(event) }>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Fragment>
                                  <div className="form-group">
                                      <div className="row">
                                          <div className="col-xl-12">
                                              <label>{t('detail.date')} <span style={{color: 'red'}}>*</span></label>
                                              {/* <input className="form-control" 
                                                    type="date" 
                                                    name="dt_dateVisit" 
                                                    onChange = { this.handleChange.bind(this) }/> */}
                                              
                                                  <KeyboardDatePicker
                                                    clearable
                                                    value={ this.state.date }
                                                    placeholder="ngày/tháng/năm"
                                                    onChange={date => this.handleDateChange(date)}
                                                    minDate={new Date()}
                                                    format="dd/MM/yyyy"
                                                    style={{width:"100%"}}
                                                  />
                                                  {/* <TimePicker
                                                    clearable
                                                    // name="dt_dateVisit"
                                                    ampm={false}
                                                    label={t('detail.date')}
                                                    value={ new Date() }
                                                    onChange={(x, event) => this.getDate(x,event)}
                                                  /> */}
                                                
                                              

                                              { this.state.dateMeetingValidate.length > 0 && <p className="text-danger text-left">{ this.state.dateMeetingValidate.map((err) => err) }</p> }

                                          </div>
                                      </div>
                                  </div>
                                  
                                  <div className="form-group">
                                      <div className="row">
                                          <div className="col-xl-6">
                                              <label>{t('detail.time')} <b>{t('detail.from')}:</b> <span style={{color: 'red'}}>*</span></label>
                                                  <TimePicker
                                                    clearable
                                                    // name="dt_dateVisit"
                                                    placeholder="giờ:phút"
                                                    ampm={false}
                                                    value={ this.state.time_from }
                                                    onChange={(time) => this.handleTimeChange(time)}
                                                    style={{width:"100%"}}
                                                  />
                                              {/* <input className="form-control" type="time" name="dt_from" onChange = { this.handleChange.bind(this) }/> */}
                                              { this.state.timeFromValidate.length > 0 && <p className="text-danger text-left">{ this.state.timeFromValidate.map((err) => err) }</p> }
                                          </div>
                                          <div className="col-xl-6">
                                              <label>{t('detail.time')} <b>{t('detail.to')}:</b><span style={{color: 'red'}}>*</span></label>
                                              <TimePicker
                                                clearable
                                                // name="dt_dateVisit"
                                                placeholder="giờ:phút"
                                                ampm={false}
                                                value={ this.state.time_to }
                                                onChange={(time) => this.handleTimeToChange(time)}
                                                style={{width:"100%"}}
                                              />
                                              {/* <input className="form-control" type="time" name="dt_to" onChange = { this.handleChange.bind(this) }/> */}
                                              { this.state.timeToValidate.length > 0 && <p className="text-danger text-left">{ this.state.timeToValidate.map((err) => err) }</p> }

                                          </div>
                                      </div>
                                  </div>
                                  </Fragment>
                                </MuiPickersUtilsProvider>
                                <div className="row text-center">
                                    <div className="col-xl-6 form-group">
                                      <Link to={"/checkin/type-employee/" + this.props.match.params.customer_id}><button type="button" className="btn btn-light form-control">{t('detail.back')}</button></Link>
                                    </div>
                                    <div className="col-xl-6 form-group">
                                      <button type="submit" className="btn btn-primary form-control">{t('detail.next')}</button>
                                    </div>
                                </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withTranslation()(Meeting);