import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Employee from './Employee';
import Host from './Host';
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Select from 'react-select'

class Purpose extends Component {

    constructor(props){
        super(props);
        this.state = {
            cb_visitorType : 0,
            isShowEmployee : false,
            visitors_type : [],
            visitor_type_id : null,
            employee_id : null,
            employee_id_fromChild : null,
            isRedirect : false,
            meeting_note : '',
            setting : [],
            location_type : null
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleCallback = (childData) => {

        localStorage.setItem('meeting_note', childData);
        this.setState({ meeting_note : childData });
        
    }

    

    handleChange(event) {
        // console.log(this.state.setting);
        // console.log(event);
        this.setState({ 
            isShowEmployee : true ,
            cb_visitorType : event.value,
            visitor_type_id : event.value
        });

        this.showVisitorType(event.value);      
    }

    goNext = () => {
        if(this.state.visitor_type_id === null){
            return Swal.fire('Thông báo!', "Vui lòng chọn mục đích gặp!", "warning");
        }
        if(this.state.location_type === "building")
        {
            if(this.state.setting.company_isActive === 1)
            {
                if(localStorage.getItem("company_id") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng chọn công ty!", "warning");
                }
            }
            
            if(this.state.setting.department_isActive === 1)
            {
                if(localStorage.getItem("department_id") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng chọn phòng ban!", "warning");
                }
            }
            if(this.state.setting.employee_isActive === 1)
            {
                if(localStorage.getItem("employee_id") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng chọn nhân viên!", "warning");
                }
            }
            if(this.state.setting.hostNote_isActive === 1)
            {
                if(localStorage.getItem("meeting_note") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng điền thông tin người cần gặp!", "warning");
                }
            }
            this.setState({ isRedirect : true });
        }
        else if(this.state.location_type === "company")
        {
            if(this.state.setting.department_isActive === 1)
            {
                if(localStorage.getItem("department_id") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng chọn phòng ban!", "warning");
                }
            }
            if(this.state.setting.employee_isActive === 1)
            {
                if(localStorage.getItem("employee_id") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng chọn nhân viên!", "warning");
                }
            }
            if(this.state.setting.hostNote_isActive === 1)
            {
                if(localStorage.getItem("meeting_note") === null)
                {
                    return Swal.fire('Thông báo!', "Vui lòng điền thông tin người cần gặp!", "warning");
                }
            }
            this.setState({ isRedirect : true });
        }
    }
    

    componentDidMount(){
        this.refreshList();
        let company = JSON.parse(localStorage.getItem("company_data"));
        let setting = JSON.parse(localStorage.getItem("setting"));
        let location_type = localStorage.getItem("location_type");
        this.setState({
            company_logo : company.company_logo,
            setting : setting,
            location_type : location_type,
        });
    }

    async showVisitorType(visitor_type_id){
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor-type/show/" + this.props.match.params.customer_id + "/" +visitor_type_id;

        await axios({
            method: "get",
            url: api_url,
            headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
            localStorage.setItem('visitor_type', JSON.stringify(result.data.data[0]));      
        });
    }

    async refreshList(){
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor-type/get/" + this.props.match.params.customer_id;

        await axios({
            method: "get",
            url: api_url,
            headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
            let visitor_type_list = [];
            result.data.data.map(visitor_type => {

                visitor_type_list.push({
                    value : visitor_type.id,
                    label : visitor_type.description,
                });
            });
            this.setState({ visitors_type : visitor_type_list });
        });
    }


    

    render() {
        const { t } = this.props;

        let isShowEmployee = this.state.isShowEmployee;
        let elmForm = null;

        if(isShowEmployee === true)
        {
            elmForm = <Host parentCallback = {this.handleCallback} customer_id={ this.props.match.params.customer_id }></Host>
            // elmForm = <Employee parentCallback = {this.handleCallback} employee_id={ this.state.employee_id } customer_id={ this.props.match.params.customer_id }></Employee>
        }

        if(this.state.isRedirect)
        {
            let url = "/checkin/meeting-sign/" + this.props.match.params.customer_id;
            // return <Redirect to={url} />
            window.location.href = url;
        }
        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-8 col-md-8 px-5 text-center">
                       
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                                <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                <h3>{t('visitor_type.title')}</h3>
                            
                                <div className="form-group">
                                    <Select options={this.state.visitors_type} name="cb_visitorType" onChange={ this.handleChange }/>
                                    {/* <select value={ this.state.cb_visitorType } name="cb_visitorType" className="form-control cb_purpose" onChange={ this.handleChange }>
                                        <option value={null}>{t('visitor_type.choose')}</option>
                                        { this.state.visitors_type.map(visitor_type => 
                                            <option key={ visitor_type.id } value={ visitor_type.id }>{ visitor_type.description }</option>
                                        )}
                                    </select> */}

                                </div>
                                {elmForm}
                                <div className="form-group">
                                    <button className="btn btn-success col-xl-4 col-8" onClick={ this.goNext }>{t('visitor.next')}</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Purpose);