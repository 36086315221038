import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Select from 'react-select'
import Employee from './Employee';

class Host extends Component {

    constructor(props){
        super(props);
        this.state = {
            company_id : null,
            department_id : null,
            employee_id : null,
            show_employee : false,

            companies : [],
            cb_companies : [],
            cb_departments : [],
            departments : [],
            employees : [],
            cb_employees : [],

            department_isChoose : false,
            location_type : null,
            setting : [],

            selectedOptionsDept : [],
            selectedOptionsEmp : []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDepartment = this.handleChangeDepartment.bind(this);
        this.handleChangeEmployee = this.handleChangeEmployee.bind(this);
        this.handleChangeNote = this.handleChangeNote.bind(this);
        
    }
    setRef = selectRef => {
        this.selectRef = selectRef;
    };

    handleCallback = (childData) => {

        // localStorage.setItem('meeting_note', childData);
        // this.setState({ meeting_note : childData });
        
    }

    async getEmployee(department_id){
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/employee/get/host/" + this.props.customer_id + 
                        "?company_id=" + this.state.company_id + "&department_id=" + department_id;
        await axios({
            method: "get",
            url: api_url,
            headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
            let employees = [];
            // console.log(this.selectRef.clearValue);
            
            result.data.map(employee => {

                employees.push({
                    value : employee.id,
                    label : employee.first_name + " " + employee.last_name,
                });
            });
            this.setState({
                cb_employees : employees,
                employees : result.data
            });
        });
    }

    handleChange(event) {

        let department_list = [];
        let departments = [];
        let companies = this.state.companies;
        companies.map(company => {
            if(event.value === company.id)
            {
                company.department.map(dept => {
                    department_list.push({
                        value : dept.id,
                        label : dept.department_name,
                    });
                });
                departments = company.department;   
            }
        });
        this.setState({
            cb_departments : department_list,
            departments : departments,
            cb_employees : [],
            employees : [],
            department_isChoose : true,
            company_id : event.value,
            selectedOptionsDept : [],
            selectedOptionsEmp : []
        });
        // console.log(this.selectRef);
        localStorage.setItem('company_id', event.value);
        localStorage.removeItem('department_id');
        localStorage.removeItem('employee_id');
    }

    handleChangeDepartment(event){
        if(event !== null)
        {
            this.setState({
                department_id : event.value,
                show_employee : true,
                selectedOptionsDept : event,
                selectedOptionsEmp : []
            });
            localStorage.setItem('department_id', event.value);
            localStorage.removeItem('employee_id');
            this.getEmployee(event.value);
        }
       
    }

    handleChangeEmployee(event){
        if(event !== null)
        {
            this.setState({
                selectedOptionsEmp : event
            });
            localStorage.setItem('employee_id', event.value);
        }
        
    }

    handleChangeNote = (event) => {
        localStorage.setItem('meeting_note', event.target.value);
    }

    componentDidMount(){
        this.refreshList();
        let location_type = localStorage.getItem("location_type");
        let setting = JSON.parse(localStorage.getItem("setting"));
        this.setState({
            location_type : location_type,
            setting : setting
        });
    }


    async refreshList(){
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/employee/get/" + this.props.customer_id;
        await axios({
            method: "get",
            url: api_url,
            headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
            if(this.state.location_type === "building")
            {
                let company_list = [];
                result.data.map(company => {
    
                    company_list.push({
                        value : company.id,
                        label : company.company_name,
                    });
                });
                this.setState({
                    cb_companies : company_list,
                    companies : result.data
                });
            }else if(this.state.location_type === "company")
            {
                let department_list = [];
                let departments = [];
                let companies = result.data;
                let company_id = null;
                companies.map(company => {
                    company.department.map(dept => {
                        department_list.push({
                            value : dept.id,
                            label : dept.department_name,
                        });
                    });
                    company_id = company.id;
                    departments = company.department;   
                });
                this.setState({
                    cb_departments : department_list,
                    departments : departments,
                    company_id : company_id
                });
                localStorage.setItem('company_id', company_id);
            }
            
        });
    }

    render() {
        const { t } = this.props;

        let elemt = "";
        let elemt1 = "";

        if(this.state.location_type === "building")
        {
            if(this.state.setting.company_isActive === 1)
            {
                elemt = <div>
                            <h3>Chọn công ty? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <Select options={this.state.cb_companies} name="cb_company" onChange={ this.handleChange }/>
                            </div>
                        </div>
                if(this.state.setting.department_isActive === 0)
                {
                    elemt1 = 
                            <div>
                                <h3>Ghi chú hẹn? <span style={{color: 'red'}}>*</span></h3>
                                <div className="form-group">
                                    <input className="form-control" 
                                        name="txt_meeting_note" 
                                        placeholder="Vui lòng nhập thông tin người cần gặp..." 
                                        onChange={ this.handleChangeNote.bind(this) }/>
                                </div>
                            </div>
                                
                }
            }
            else
            {
                elemt = 
                            <div>
                                <h3>Ghi chú hẹn? <span style={{color: 'red'}}>*</span></h3>
                                <div className="form-group">
                                    <input className="form-control" 
                                        name="txt_meeting_note" 
                                        placeholder="Vui lòng nhập thông tin người cần gặp..." 
                                        onChange={ this.handleChangeNote.bind(this) }/>
                                </div>
                            </div>
            }
            
        }else if(this.state.location_type === "company")
        {
            if(this.state.setting.department_isActive === 1)
            {
                elemt = <div>
                            <h3>Chọn phòng ban? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <Select options={this.state.cb_departments}  
                                        name="cb_department" 
                                        onChange={ this.handleChangeDepartment }
                                />
                            </div>
                        </div> 
                        
            }
            else{
                elemt = <div>
                            <h3>Ghi chú hẹn? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <input className="form-control" 
                                    name="txt_meeting_note" 
                                    placeholder="Vui lòng nhập thông tin người cần gặp..." 
                                    onChange={ this.handleChangeNote.bind(this) }/>
                            </div>
                        </div>
                        
            }
        }

        return (
            <>
                    { elemt }
                    { elemt1 }
                    { this.state.department_isChoose && this.state.setting.department_isActive === 1 &&
                        <div>
                            <h3>Chọn phòng ban? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <Select 
                                        defaultValue={this.state.selectedOptionsDept}
                                        value={this.state.selectedOptionsDept}
                                        options={this.state.cb_departments}  
                                        name="cb_department" 
                                        onChange={ this.handleChangeDepartment }
                                />
                            </div>
                        </div>
                        
                    }

                    { this.state.show_employee && this.state.setting.employee_isActive === 1 &&
                        <div>
                            <h3>{t('employee.title')} <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <Select 
                                        defaultValue={this.state.selectedOptionsEmp}
                                        value={this.state.selectedOptionsEmp}
                                        options={this.state.cb_employees} 
                                        name="cb_employee" 
                                        onChange={ this.handleChangeEmployee } 
                                />
                            </div>
                        </div>
                        
                    }
                    { this.state.setting.hostNote_isActive === 1 && 
                        <div>
                            <h3>Ghi chú hẹn? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <input className="form-control" 
                                    name="txt_meeting_note" 
                                    placeholder="Vui lòng nhập thông tin người cần gặp..." 
                                    onChange={ this.handleChangeNote.bind(this) }/>
                            </div>
                        </div>
                        
                    }
                    
                </>
        );
    }
}

export default withTranslation()(Host);