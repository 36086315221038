import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Select from 'react-select'

class Employee extends Component {

    constructor(props){
        super(props);
        this.state = {
            employee_id : null,
            employees : [],
            cb_employees : [],
            setting : [],
        };
    }

    handleChange = (event) => {
        localStorage.setItem('employee_id', event.value);
    }

    handleChangeNote = (event) => {
        localStorage.setItem('meeting_note', event.target.value);
    }

    

    componentDidMount(){
        let setting = JSON.parse(localStorage.getItem('setting'));
        this.setState({
            setting : setting
        });
        // console.log(setting);
        this.refreshList();
    }


    async refreshList(){
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/employee/get/host/" + this.props.customer_id + 
                        "?company_id=" + this.props.company_id + "&department_id=" + this.props.department_id;
        await axios({
            method: "get",
            url: api_url,
            headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
            let employees = [];
            result.data.map(employee => {

                employees.push({
                    value : employee.id,
                    label : employee.first_name + " " + employee.last_name,
                });
            });
            this.setState({
                cb_employees : employees,
                employees : result.data
            });
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                    <h3>{t('employee.title')} <span style={{color: 'red'}}>*</span></h3>
                    { this.state.setting.employee_isActive === 1 && 
                        <div className="form-group">
                            <Select options={this.state.cb_employees} name="cb_employee" onChange={ this.handleChange }/>
                        </div>
                    }
                    { this.state.setting.hostNote_isActive === 1 && 
                        <div>
                            <h3>Ghi chú hẹn? <span style={{color: 'red'}}>*</span></h3>
                            <div className="form-group">
                                <input className="form-control" 
                                    name="txt_meeting_note" 
                                    placeholder="Vui lòng nhập thông tin người cần gặp..." 
                                    onChange={ this.handleChangeNote.bind(this) }/>
                            </div>
                        </div>
                        
                    }
                    
                    
                </>
        );
    }
}

export default withTranslation()(Employee);