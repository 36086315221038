import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Header() {

    const { t, i18n } = useTranslation();

    function handleClick(lang)
    {
      i18n.changeLanguage(lang);
    }

    return (
      <nav id="navbar-main" className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="https://qi.com.vn">
            <img src="/assets/img/brand/Qiosk_long_logo.png" alt=""/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <a href="https://qi.com.vn">
                    <img src="/assets/img/brand/Qiosk_long_logo.png" alt=""/>
                  </a>
                </div>
                <div className="col-6 collapse-close">
                  <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="!#" className="nav-link">
                  <span className="nav-link-inner--text">Trang chủ</span>
                </a>
              </li>
              
              <li className="nav-item">
                <a href="!#" className="nav-link">
                  <span className="nav-link-inner--text">Chính sách</span>
                </a>
              </li>
            </ul>
            <hr className="d-lg-none" />
            <ul className="navbar-nav align-items-lg-center ml-lg-auto">
              <li className="nav-item">
                <div className="btn-group">
                  <a className="nav-link nav-link-icon btn-md dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-globe-asia" />
                    <span className="nav-link-inner--text d-lg-none">{t('headerlang')}</span>
                  </a>
                  <div className="dropdown-menu dropdown-small ">
                    <button className="dropdown-item" onClick={() => handleClick('vi')}>
                      <img src="/assets/img/icons/flags/1x1/vn.svg" width="10%" alt="" /> Việt Nam
                    </button>
                    <button className="dropdown-item" onClick={() => handleClick('en')}>
                      <img src="/assets/img/icons/flags/1x1/us.svg" width="10%" alt="" /> United State
                    </button>
                  </div>
                </div>
              </li>
              <li className="nav-item" />
              <li className="nav-item" />

            </ul>
          </div>
        </div>
      </nav>
  );
}

export default Header;