import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import axios from 'axios';


class CapturePhoto extends Component {
    constructor (props) {
        super(props);
        this.state = {
            imageSrc : "",
            isCapture : false,
            isProcess : false,
            isRedirect : false
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        })
      };
    
    retake = () => {
        this.setState({
            imageSrc : "",
            isCapture : false,
            qr_code : false
        })
      };
    
    useImg =() => {
        this.setState({
            isProcess : true
        });
        let bodyFormData = new FormData();
        bodyFormData.append('img', this.state.imageSrc);

        
        let data = { img : this.state.imageSrc };

        let customer_id = this.props.match.params.customer_id;
        let visitor_id = localStorage.getItem('visitor_id');
        let api_url = "https://www.qior.com.vn/signimg/" + customer_id + "/" + visitor_id;
        


        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        // .then(response => response.json())
        .then(result => {
            if(result.data.status_code === 200){
                this.setState({
                  isProcess : false
                });
                return Swal.fire({
                    icon: 'success',
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
            else if(result.data.status_code === 205)
            {
                this.setState({
                    isProcess : false
                  });
                return Swal.fire('Lỗi 205!', result.data.message , "error");
            }
            else if(result.data.status_code === 215)
            {
                this.setState({
                    isProcess : false
                });
                return Swal.fire('Lỗi 215!', result.data.message , "error");
            }
            else if(result.data.status_code === 500)
            {
                this.setState({
                    isProcess : false
                });
                return Swal.fire('Lỗi 500!', result.data.message , "error");
            }
            console.log(result);
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            isProcess : true
        });
        let data = { img : event.target.img.value };

        let visitor_id = localStorage.getItem('visitor_id');
        let detail_id = localStorage.getItem('detail_id');
        let customer_id = this.props.match.params.customer_id;

        let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/store-img-visitor-detail/" + customer_id + "/" + visitor_id + "/" + detail_id;
        let bodyFormData = new FormData();
        bodyFormData.append('img', event.target.img.value);
        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
            if(result.data.status_code === 200){
                event.target.reset();
                this.setState({
                  isBlocking : false,
                  isRedirect : true, 
                  isProcess : false
                });
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
        // fetch(api_url, {
        //     method : 'POST',
        //     headers: {
        //     'Accept' : 'application/json',
        //     'Content-Type' : 'application/json',
        //     // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
        //     },
        //     body: JSON.stringify(data)
        // })
        // .then(response => response.json())
        // .then(result => {
        //     if(result.status_code === 200){
        //         event.target.reset();
        //         this.setState({
        //           isBlocking : false,
        //           isRedirect : true, 
        //           isProcess : false
        //         });
        //     }
        //   })
        //   .catch(error => {
        //     console.log(JSON.parse(error));
        // })
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        let qr = localStorage.getItem('qr-checkin');

        this.setState({
            company_logo : company.company_logo,
            qr_code : qr
        })
    }
    

    render() {

        if(this.state.isRedirect)
        {
          let url = "/checkin/thing-carrying/" + this.props.match.params.customer_id;
        //   return <Redirect to={ url } />
        window.location.href = url;
        }

        let isCapture = this.state.isCapture;
        let elmForm = null;
        if(isCapture === true)
        {
            // elmForm = <TakePhoto imgsrc={ this.state.imageSrc }/>;
            elmForm = <>
                    <div className="row justify-content-center">
                        <form onSubmit={ (e) => this.handleSubmit (e) }>
                            <div className="form-group">
                                <TakePhoto imgsrc={ this.state.imageSrc }/>
                                <input type="hidden" name="img" value={ this.state.imageSrc }/>
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-info" onClick={this.retake}>Retake</button>
                                {!this.state.qr_code && <button type="button" className="btn btn-success" onClick={ this.useImg }>Use</button>}
                            </div>                    
                            <button type="submit" className="btn btn-primary">NEXT STEP</button>
                        </form>
                    </div>


            </>
        }
        else{
            elmForm = <>
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Capture</button>
                        </div>
                    </>

        }
        

        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                {/* <div className="row">
                                <div className="col-xl-6">
                                    <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                                </div>
                                <div className="col-xl-6"> */}
                                    { elmForm }
                                    { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }
 
                                    {/* { this.state.imageSrc } */}
                                {/* </div>
                                </div>
                                <br/>
                                <div className="form-group">
                                <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Snapshot</button>
                                </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


class TakePhoto extends Component {
    
    render() {
    
        const imgsrc = this.props.imgsrc;
        return (
          <div>
            
            <img width="100%" src={ imgsrc } alt=""/>


          </div>
        );
      }
}


export default CapturePhoto;