import useWebSocket from 'react-use-websocket';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { loadModels, getFullFaceDescription,extractFaceFromBox, createMatcher } from '../../api/face';
import io from "socket.io-client";
import swal from 'sweetalert';
import axios from 'axios';

// Import face profile
const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 160;

const ws = new WebSocket("wss://face.pvndns.net/pub/facerecognize");

class websocket_face extends Component {
    constructor(props) {
        super(props);
        this.webcam = React.createRef();

        this.state = {
            fullDesc: null,
            detections: null,
            descriptors: null,
            faceMatcher: null,
            match: null,
            face : null,
            company_logo : "",
            face_img : "",
            name : ""        
          };

    }
    
      componentWillMount = async () => {
        await loadModels();
        // this.setState({ faceMatcher: await createMatcher(JSON_PROFILE) });
        this.setInputDevice();
      };
      componentDidMount(){
        // let company = JSON.parse(localStorage.getItem("company_data"));
        // this.setState({
        //     company_logo : company.company_logo
        // })
        ws.onopen = () => {
          console.log("connected websocket main component");
        };
    
      }
    
      setInputDevice = () => {
        navigator.mediaDevices.enumerateDevices().then(async devices => {
          let inputDevice = await devices.filter(
            device => device.kind === 'videoinput'
          );
          if (inputDevice.length < 2) {
            await this.setState({
              facingMode: 'user'
            });
          } else {
            await this.setState({
              facingMode: { exact: 'environment' }
            });
          }
          this.startCapture();
        });
      };
    
      startCapture = () => {
        this.interval = setInterval(() => {
          this.capture();
        }, 1000);
      };
    
      componentWillUnmount() {
        clearInterval(this.interval);
      }

      capture = async () => {
        const name_id = {fullname : "", uuid : ""};

        if (!!this.webcam.current) {
    
          // Get face Detection Box
          await getFullFaceDescription(
            this.webcam.current.getScreenshot(),
            inputSize
          ).then(fullDesc => {
            if (!!fullDesc) {
              this.setState({
                detections: fullDesc.map(fd => fd.detection),
                descriptors: fullDesc.map(fd => fd.descriptor)
              });
              let detections = fullDesc.map(fd => fd.detection);
              if(detections.length > 0)
              {
                let data =  {"command": "face_recognize", "image":  this.webcam.current.getScreenshot() };
                // this.connect(data);
                ws.send(JSON.stringify(data));
                ws.onmessage = function (event) {

                    let mes = JSON.parse(event.data);
                    let faces = mes.message;
                    console.log(mes);
                    if(Array.isArray(faces))
                    {
                      let fullname = faces.map((face) => face.fullname);
                      if(fullname === '')
                      {
                        fullname = 'unknown';
                      }
                      else
                      {
                        document.getElementById('message').innerHTML = fullname;
                      }
                    }
                    else
                    {
                      document.getElementById('message').innerHTML = faces;

                    }
        
                };
                
                // this.state.ws.close();

              }
              
              
            }
          });
    
    
          if (!!this.state.descriptors && !!this.state.faceMatcher) {
            let match = await this.state.descriptors.map(descriptor =>
              this.state.faceMatcher.findBestMatch(descriptor)
            );
            this.setState({ match });
          }
        }
      };
  

    timeout = 250; // Initial timeout duration as a class variable

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    // connect = (data) => {
    //     let that = this; // cache the this
    //     var connectInterval;

    //     // websocket onopen event listener
    //     ws.onopen = () => {
    //         console.log("connected websocket main component");

            
    //         // this.setState({ ws: ws });

    //         that.timeout = 250; // reset timer to 250 on open of websocket connection 
    //         clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    //     };

    //     ws.onmessage = function (event) {
    //         console.log("Message received..." + event.data);
    //         // event.data.map(val => console.log(val));
    //         console.log(event.data);
    //         return event.data;

    //     };

    //     // websocket onclose event listener
    //     ws.onclose = e => {
    //         console.log(
    //             `Socket is closed. Reconnect will be attempted in ${Math.min(
    //                 10000 / 1000,
    //                 (that.timeout + that.timeout) / 1000
    //             )} second.`,
    //             e.reason
    //         );

    //         that.timeout = that.timeout + that.timeout; //increment retry interval
    //         connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    //     };

    //     // websocket onerror event listener
    //     ws.onerror = err => {
    //         console.error(
    //             "Socket encountered error: ",
    //             err.message,
    //             "Closing socket"
    //         );

    //         ws.close();
    //     };
    // };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };


    render() {
        const { detections, match, face } = this.state;
        let videoConstraints = null;
        videoConstraints = {
          width: WIDTH,
          facingMode: 'user'
        };
    
        let drawBox = null;
        if (!!detections) {
    
          drawBox = detections.map((detection, i) => {
            let _H = detection.box.height;
            let _W = detection.box.width;
            let _X = detection.box._x;
            let _Y = detection.box._y;
            
            return (
              <div key={i}>
                <div
                  style={{
                    border: 'solid',
                    borderColor: 'blue',
                    height: _H,
                    width: _W,
                    transform: `translate(${_X}px,${_Y}px)`
                  }}
                >
                  <p id="message" style={{ fontWeight: 'bold' }}></p>
                  {!!match && !!match[i] ? (
                    <p
                      style={{
                        backgroundColor: 'blue',
                        border: 'solid',
                        borderColor: 'blue',
                        marginTop: 0,
                        color: '#fff',
                        transform: `translate(-3px,${_H}px)`
                      }}
                    >
                      {match[i]._label}
                    </p>
                  ) : null}
                </div>
              </div>
            );
          });
        }
    
        return (
            
            <div>
            {/* Header */}
            <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                          {/* <img src={this.state.face_img}/> */}
                          <div className="card-body px-lg-5 py-lg-5">
                              <div className="text-center">
                                  <img style={{ marginTop:"4px", marginBottom: "4px"}} width="200px" height="200px" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                  <div className="row justify-content-center">
                                      <div className="col-xl-6">
                                          <div
                                            className="Camera"
                                            style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                            }}
                                          >
                                          <div
                                          style={{
                                              width: "100%",
                                              height: HEIGHT
                                          }}
                                          >
                                            <div>
                                                {!!videoConstraints ? (
                                                <div style={{ position: 'absolute' }}>
                                                    <Webcam
                                                    audio={false}
                                                    width="100%"
                                                    ref={this.webcam}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={videoConstraints}
                                                    />
                                                </div>
                                                ) : null}
                                                {!!drawBox ? drawBox : null}
                                            </div>
                                            {/* <img src={this.state.face}/> */}
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
          
        );
      }
}


export default websocket_face;