import React, { Component } from 'react';
import {Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Checkin from '../Checkin/Checkin';
import VisitorSign from '../VisitorSign/VisitorSign';
import Vaccine from '../VisitorSign/Vaccine';
// import ThingCarrying from '../ThingCarrying/ThingCarrying';
import Module from '../Checkin/Module/Module';
import Visitor from '../Checkin/Module/Visitor';
import QRContainer from '../Checkin/Module/QRContainer/QRContainer';

// Checkin New import js
import VisitorNew from '../App/Checkin/NewRegister/VisitorNew';
import VaccineNew from '../App/Checkin/NewRegister/VaccineNew';
import FaceCapture from '../App/Checkin/NewRegister/FaceCapture';

// Checkin Registered 
import FaceCheckin_v1 from '../App/Checkin/Registered/Module/FaceCheckin_v1';
import FaceCheckin_v2 from '../App/Checkin/Registered/Module/FaceCheckin_v2';

import QRCheckin from '../App/Checkin/Registered/Module/QRCheckin';

import VisitorCheckin from '../App/Checkin/Registered/VisitorCheckin';
import VaccineCheckin from '../App/Checkin/Registered/VaccineCheckin';
import CapturePhoto from '../App/Checkin/CapturePhoto';

// Checkin import js
import Purpose from '../App/Checkin/Purpose';
import Meeting from '../App/Checkin/Meeting';
import ThingCarrying from '../App/Checkin/ThingCarrying';
import NDA from '../App/Checkin/NDA';

import websocket_face from '../TestWebSocket/websocket_face'
import FaceSignWebSocket from '../TestWebSocket/face-sign-websocket';

// Checkout import js
import QROut from '../App/Checkout/Module/QROut';
import Cam from '../App/Checkout/Module/Cam';
import FaceRecognitionOut from '../App/Checkout/Module/FaceRecognitionOut';
import FaceRecognitionOut_v2 from '../App/Checkout/Module/FaceRecognitionOut_v2';

import ModuleCheckout from '../App/Checkout/ModuleCheckout';
import VideoInput from '../VideoInput';
import FaceRecognizeRealTime from '../Checkin/Module/FaceRecognize/FaceRecognizeRealTime';






class RouterURLv1 extends Component {
    render() {
        return (
            <Switch>

                {/* <Route exact path="/" component={VideoInput}/> */}
                {/* <Route exact path="/websocket-realtime" component={websocket_face}/>
                <Route exact path="/websocket-face-capture" component={FaceSignWebSocket}/> */}


                <Route exact path="/:customer_id/:device_code" component={Home}/>
                {/* <Route exact path="/checkin/:customer_id" component={Checkin}/> */}
                {/* Registered */}
                    <Route exact path="/checkin/module/:customer_id" component={Module}/>
                    <Route exact path="/checkin/face-recognize/:customer_id" component={FaceCheckin_v2}/>
                    <Route exact path="/checkin/capture-photo/:customer_id" component={CapturePhoto}/>

                    {/* <Route exact path="/checkin/face-recognize-real-time/:customer_id" component={FaceRecognizeRealTime}/> */}

                    <Route exact path="/checkin/face-recognize/visitor/:customer_id" component={VisitorCheckin}/>
                    {/* <Route exact path="/checkin/face-recognize/vaccine/:customer_id" component={VaccineCheckin}/> */}

                    <Route exact path="/checkin/qr-code/:customer_id" component={QRCheckin}/>
                    <Route exact path="/checkin/qr-code/visitor/:customer_id" component={Visitor}/>
                {/* {} */}
                <Route exact path="/checkin/type-employee/:customer_id" component={Purpose}/>
                <Route exact path="/checkin/visitor-sign/:customer_id" component={VisitorNew}/>
                {/* <Route exact path="/checkin/visitor-vaccinated/:customer_id" component={VaccineNew}/> */}
                <Route exact path="/checkin/meeting-sign/:customer_id" component={Meeting}/>
                <Route exact path="/checkin/face-capture/:customer_id" component={FaceCapture}/>
                <Route exact path="/checkin/thing-carrying/:customer_id" component={ThingCarrying}/>
                <Route exact path="/checkin/nda/:customer_id" component={NDA}/>
                {/* Checkout */}
                <Route exact path="/checkout/module/:customer_id" component={ModuleCheckout}/>

                <Route exact path="/checkout/checkout-face/:customer_id" component={FaceRecognitionOut_v2}/>
                <Route exact path="/checkout/checkout-qr/:customer_id" component={QROut}/>
                {/* {} */}
                
            </Switch>
        );
    }
}

export default RouterURLv1;