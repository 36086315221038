import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import axios from 'axios';
import VisitorCheckin from '../VisitorCheckin';
import swal from 'sweetalert';
import { withTranslation } from 'react-i18next';
import { loadModels, getFullFaceDescription,extractFaceFromBox } from '../../../../../api/face';
import io from "socket.io-client";


// let endPoint = "https://www.qior.com.vn"; // http://www.qior.com.vn:5002
// // let endPoint = "http://210.245.55.12:3006";
// let socket = io.connect(`${endPoint}`);

const inputSize = 160;

class FaceCheckin extends Component {
    constructor (props) {
        super(props);
        this.state = {
            imageSrc : "",
            isCapture : false,
            isProcess : false,
            isRedirect : false,
            visitors_data : [],
            isRecognize : false,
            isDetect : false,
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        });
        console.log(this.webcam.getScreenshot());

      };
    
    retake = () => {
        this.setState({
            imageSrc : "",
            isCapture : false
        })
      };
    
    useImg = async () => {

        let customer_id = this.props.match.params.customer_id;
        this.setState({
            isProcess : true
        });

        await getFullFaceDescription(
            this.state.imageSrc,
            inputSize
          ).then(fullDesc => {
            if (!!fullDesc) {
                // Extract face from webcam
                let detections = fullDesc.map(fd => fd.detection);
                if(detections.length > 0)
                {
                    extractFaceFromBox(this.state.imageSrc, detections[0].box)
                    .then(result => {

                        let isRealtime = true;
                        let url500 = '/checkin/visitor-sign/' + this.props.match.params.customer_id;
                        // socket.emit("message", { result, customer_id, isRealtime});
                        // socket.once("message", result => {
                        //     console.log(result);
                        //     if(result.status_code === 210)
                        //     {
                        //         swal({
                        //             icon: "error",
                        //             title: "Lỗi 210!",
                        //             text: result.message,
                        //             type: "error",
                        //             timer: 2000,
                        //             showConfirmButton: false
                        //         });

                        //         window.location.href = '/checkin/' + this.props.match.params.customer_id;
                        //     }
                        //     else if(result.status_code === 500)
                        //     {
                        //         swal({
                        //             icon: "error",
                        //             title: "Lỗi 500!",
                        //             text: result.message + ". Chúng tôi sẽ chuyển hướng bạn đến trang đăng ký mới!",
                        //             type: "error",
                        //             showConfirmButton: false
                        //         }).then(function() {
                        
                        //             window.location.href = url500;
                        //         });

                        //     }
                        //     else{
                        //         localStorage.setItem('visitor', JSON.stringify(result));
                        //         localStorage.setItem('img', this.state.imageSrc);
                        //         localStorage.setItem('module', 'face-recognize');

                        //         this.setState({
                        //             isProcess : false,
                        //             isRedirect : true,
                        //             isRecognize : true
                        //         });
                        //     }
                        // });
                    });
                }
                else
                {
                    this.setState({
                        isProcess : false,
                    });
                    return Swal.fire('Lỗi 215!', "Không tìm thấy khuôn mặt xin vui lòng thử lại!" , "error");
                }
                    
            }
        });

    }

    componentWillMount = async () => {
        await loadModels();
      };

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }
    

    render() {
        const { t } = this.props;

        if(this.state.isRedirect)
        {
          let url = "/checkin/face-recognize/visitor/" + this.props.match.params.customer_id;
          return <Redirect to={ url } />
        }

        let isCapture = this.state.isCapture;
        let elmForm = null;
        if(isCapture === true)
        {
            // elmForm = <TakePhoto imgsrc={ this.state.imageSrc }/>;
            elmForm = <>
                    <div className="row justify-content-center">
                        <form onSubmit={ (e) => this.handleSubmit (e) }>
                            <div className="form-group">
                                <TakePhoto imgsrc={ this.state.imageSrc }/>
                                <input type="hidden" name="img" value={ this.state.imageSrc }/>
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-info" onClick={this.retake}>Retake</button>
                                <button type="button" className="btn btn-success" onClick={ this.useImg }>Use</button>
                            </div>                    
                            {this.state.isRecognize && <button type="submit" className="btn btn-primary">NEXT STEP</button>}
                        </form>
                    </div>


            </>
        }
        else{
            elmForm = <>
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Capture</button>
                        </div>
                    </>

        }
        

        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                {/* <div className="row">
                                <div className="col-xl-6">
                                    <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                                </div>
                                <div className="col-xl-6"> */}
                                    { elmForm }
                                    { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }
 
                                    {/* { this.state.imageSrc } */}
                                {/* </div>
                                </div>
                                <br/>
                                <div className="form-group">
                                <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Snapshot</button>
                                </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


class TakePhoto extends Component {
    
    render() {
    
        const imgsrc = this.props.imgsrc;
        return (
          <div>
            
            <img width="100%" src={ imgsrc } alt=""/>


          </div>
        );
      }
}


export default withTranslation()(FaceCheckin);