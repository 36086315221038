import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { SemipolarLoading } from 'react-loadingg';


class NDA extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
    }
    state = {trimmedDataURL: null, isRedirect : false, isSign : false, isProcess: false, device_code : null,  ws: null, socket_api : null}
    sigPad = {}
    clear = () => {
        this.sigPad.clear();
        this.setState({
            trimmedDataURL: null,
            isSign : false
        });
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
            isSign : true
        })
            
    }

    timeout = 250; // Initial timeout duration as a class variable

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = (socket_api) => {
        var ws = new WebSocket(socket_api);
        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("Websocket connected");

            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = (socket_api) => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(socket_api); //check if websocket instance is closed, if so call `connect` function.
    };

    returnToHome = () => {

        this.interval = setInterval(() => {
            let redirect = localStorage.getItem("redirect");
            let url_home = localStorage.getItem("url_home");
            if(redirect === "home")
            {
                // console.log(redirect);
                this.setState({
                    isRedirect : true, 
                    isProcess : false,
                });
                localStorage.clear();
                swal({
                    icon : "success",
                    title: "Success!",
                    text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                    }).then(function() {
                    
                    window.location.href = url_home;
                });
            }
        }, 1000);

        
    };


    async submit() {
        let visitor = JSON.parse(localStorage.getItem('visitor'));

        // get object meeting employee or company
        let employee_id = localStorage.getItem('employee_id');
        let company_id = localStorage.getItem('company_id');
        let department_id = localStorage.getItem('department_id');
        let device_id = localStorage.getItem('device_id');

        // Get visitor type
        let visitor_type = JSON.parse(localStorage.getItem('visitor_type'));

        // Get detail meeting
        let detail = JSON.parse(localStorage.getItem('detail'));

        // Get thing carrying
        let thing = JSON.parse(localStorage.getItem('thing_carrying'));
        let img = localStorage.getItem('img');

        // Get face image
        let face_img = localStorage.getItem('face_img');
        let image_id = localStorage.getItem('image_id');

        // Get meeting note
        let meeting_note = localStorage.getItem('meeting_note');

        let bodyFormData = new FormData();

        bodyFormData.append('device_id', device_id);
        // Fill data visitor to form
        bodyFormData.append('visitor_id', visitor.visitor_id === null ? '' : visitor.visitor_id);
        bodyFormData.append('txt_name', visitor.txt_name);
        bodyFormData.append('dt_birth', visitor.dt_birth);
        bodyFormData.append('txt_phone_number', visitor.txt_phone_number);
        bodyFormData.append('txt_email', visitor.txt_email);
        bodyFormData.append('txt_identity', visitor.txt_identity);
        bodyFormData.append('dt_issuedOn', visitor.dt_issuedOn);
        bodyFormData.append('txt_nationality', visitor.txt_nationality);
        bodyFormData.append('txt_company', visitor.txt_company !== null ? visitor.txt_company : '-');
        bodyFormData.append('txt_position', visitor.txt_position);
        bodyFormData.append('txt_address', visitor.txt_address);

        // Choose employee
        
        bodyFormData.append('txt_note', meeting_note);
        // Choose visitor type
        bodyFormData.append('cb_purpose', visitor_type.id);
        // Choose detail meeting
        bodyFormData.append('dt_dateVisit', detail.dt_dateVisit);
        bodyFormData.append('dt_from', detail.dt_from);
        bodyFormData.append('dt_to', detail.dt_to);

        bodyFormData.append('cb_person', employee_id === null ? '' : employee_id);
        bodyFormData.append('cb_company', company_id === null ? '' : company_id);
        bodyFormData.append('cb_department', department_id === null ? '' : department_id);

        // Sign with face
        bodyFormData.append('img', img);
        bodyFormData.append('platform', "web");
        // Thing carrying
        if(thing !== null)
        {
            bodyFormData.append('txt_description', thing.txt_description);
            bodyFormData.append('img_thing', thing.img_thing  === null ? '' : thing.img_thing);
        }

         // Signature       
        if(this.state.trimmedDataURL !== null)
        {
            bodyFormData.append('sign', this.state.trimmedDataURL);
        }
        



        let customer_id = this.props.match.params.customer_id;
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/store/" + customer_id;

        await axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
            },
        })
        .then(result => {
            // console.log(result.data);
            if(result.data.status_code === 200){
                let data =  {
                    "command":"visitor_mapping",
                    "userid": image_id, 
                    "visitorid" : result.data.visitor_id
                };
                
                let url_home = "/" + this.props.match.params.customer_id + "/" + this.state.device_code

                localStorage.setItem('face_signup',  JSON.stringify(data));
                localStorage.setItem('url_home',  url_home);

                
            }
            else if(result.data.status_code === 500)
            {
                return Swal.fire('Lỗi 500!', result.data.message , "error");
            }
        })
        .catch(error => {
            console.log(JSON.parse(error));
        });

        if(image_id !== null)
        {
            let face_signup = JSON.parse(localStorage.getItem('face_signup'));
            let ws = this.state.ws;
            ws.send(JSON.stringify(face_signup));
            ws.onmessage = function (event) {
                let message = JSON.parse(event.data);
                if(message.result === true)
                {
                    localStorage.setItem("redirect", "home");
                }
            }
        }
        else
        {
            localStorage.setItem("redirect", "home");
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();

        if(this.state.trimmedDataURL !== null)
        {
            this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')});
            this.submit();

            this.setState({
                isProcess : true
            });
        }
        else
        {
            return Swal.fire('Thông báo!', "Vui lòng ký tên để hoàn tất thủ tục" , "info");
        }    
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({
            ws : null,
        });
    }

    componentDidMount = () => {
        let company = JSON.parse(localStorage.getItem("company_data"));
        let device_code = localStorage.getItem("device_id");
        let socket_api = localStorage.getItem("socket_api");
        this.setState({
            company_logo : company.company_logo,
            device_code : device_code,
            socket_api : socket_api,
        });
        this.connect(socket_api);  
        this.returnToHome();      
    }

    


    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */

    render() {
        const { t } = this.props;

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="card bg-secondary border-0 mb-0">
                                <div className="card-body px-lg-5 py-lg-5">
                                    <div className="text-center">
                                        <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                        <div className="form-group">
                                            <h1>{t('nda.title')}</h1>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-xl-6">
                                                <form onSubmit= { (e) => this.handleSubmit(e) }>
                                                    {/* <h1>{t('nda.sign')}</h1> */}
                                                    <div className="form-group">
                                                        <SignatureCanvas penColor='black'
                                                            canvasProps={{ width: 300, height: 300, className: 'sigCanvas border border-dark'}} ref={(ref) => { this.sigPad = ref }}/>
                                                            { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }

                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <button type="button" onClick={this.trim} className="btn btn-danger">{t('nda.keep-btn')}</button>
                                                        <button type="button" onClick={this.clear} className="btn btn-light">{t('nda.clear')}</button>
                                                    </div>
                                                    {this.state.isSign && <button type="submit" className="btn btn-primary">{t('nda.complete')}</button>}
                                                </form>                                                
                                            </div>
                                            
                                        </div>
                                        {/* <br/>
                                        <div className="form-group">
                                            <Link to="/checkin/meeting-sign"><button type="submit" className="btn btn-primary col-xl-3">Complete</button></Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default withTranslation()(NDA);