import React, { Component } from 'react';
import { Link, Prompt, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios';
import { withTranslation } from 'react-i18next';


class VisitorNew extends Component {

    constructor(props){
      super(props);
      this.state = {
        phone_number : '',
        phoneValidate : [],
        nameValidate : [],
        birthValidate : [],
        identityValidate : [],
        issuedOnValidate : [],
        addressValidate : [],
        emailValidate : [],

        isRedirect : false,
        isBlocking : false,
        visitor_id : null,
        customer_id : null,
        errs : []
      };
      this.handleChange = this.handleChange.bind(this);

    }


    handleValidation = (bodyFormData, event) => {

        let api_url = "https://www.qior.com.vn/api/v2/qiosk/validate-visitor-info";

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { 
              "Content-Type": "multipart/form-data",
              "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy",
            },
        })
        .then(result => {
          if(result.data.status === 200){
                let data = {
                  'visitor_id' : null,
                  'txt_name' : event.target.txt_name.value,
                  'dt_birth' : event.target.dt_birth.value,
                  'txt_phone_number' : event.target.txt_phone_number.value,
                  'txt_email' : event.target.txt_email.value,
                  'txt_identity' : event.target.txt_identity.value,
                  'dt_issuedOn' : event.target.dt_issuedOn.value,
                  'txt_nationality' : event.target.txt_nationality.value,
                  'txt_company' : event.target.txt_company.value === '' ? '-' : event.target.txt_company.value,
                  'txt_position' : event.target.txt_position.value,
                  'txt_address' : event.target.txt_address.value,
                };
                localStorage.setItem("visitor", JSON.stringify(data));
                localStorage.setItem("module", "new");

                this.setState({
                  isBlocking : false,
                  isRedirect : true, 
                });
            }
            else if(result.data.status === 404){
                let errors = result.data.error;
                
                this.setState({
                  phoneValidate : !!errors.txt_phone_number ? errors.txt_phone_number : [],
                  nameValidate: !!errors.txt_name ? errors.txt_name : [],
                  birthValidate : !!errors.dt_birth ? errors.dt_birth : [],
                  identityValidate : !!errors.txt_identity ? errors.txt_identity : [],
                  issuedOnValidate : !!errors.dt_issuedOn ? errors.dt_issuedOn : [],
                  addressValidate : !!errors.txt_address ? errors.txt_address : [],
                  emailValidate : !!errors.txt_email ? errors.txt_email : [],
                });
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
    }

    
    handleSubmit(event){
        event.preventDefault();

        let bodyFormData = new FormData();

        bodyFormData.append('txt_name', event.target.txt_name.value);
        bodyFormData.append('dt_birth', event.target.dt_birth.value);
        bodyFormData.append('txt_phone_number', event.target.txt_phone_number.value);
        bodyFormData.append('txt_email', event.target.txt_email.value);
        bodyFormData.append('txt_identity', event.target.txt_identity.value);
        bodyFormData.append('dt_issuedOn', event.target.dt_issuedOn.value);
        bodyFormData.append('txt_nationality', event.target.txt_nationality.value);
        bodyFormData.append('txt_company', event.target.txt_company.value);
        bodyFormData.append('txt_position', event.target.txt_position.value);
        bodyFormData.append('txt_address', event.target.txt_address.value);

        this.handleValidation(bodyFormData, event);
      
    }


    handleChange(event){      
        this.setState({
          [event.target.name] : event.target.value,
          isBlocking : event.target.value.length > 0
        });
    }

    handleInput(event){
      let phone_num = (event.target.validity.valid) ? event.target.value : this.state.phone_number;
      this.setState({ phone_number : phone_num });
    }

    componentDidMount(){
      let company = JSON.parse(localStorage.getItem("company_data"));
      this.setState({
          company_logo : company.company_logo
      })
    }


    render() {
      const { t } = this.props;

      if(this.state.isRedirect)
      {
        let url = "/checkin/type-employee/" + this.props.match.params.customer_id;
        // return <Redirect to={ url } />
        window.location.href = url;
      }
        
        return (
            <div>
              <Prompt when={this.state.isBlocking} message={ location => (`Bạn có chắc muốn chuyển trang ${location.pathname}. Việc thực hiện thao tác này khiến cho dữ liệu bạn đang điền trong form sẽ bị mất?` ) } />
            {/* Header */}
            {/* {this.props.match.params.visitor_type_id} */} 
            <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="card bg-secondary border-0 mb-0">
                      <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center">
                          <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />    
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <form encType="multipart/form-data" onSubmit={ (e) => this.handleSubmit(e) }>
                              <div className="form-group">
                                <label>{t('visitor.label.phone')} <span style={{color: 'red'}}>*</span></label>
                                <input type="text" className="form-control" 
                                    name="txt_phone_number"
                                    value={ this.state.phone_number }
                                    placeholder="Input your phone number ..." 
                                    pattern="[0-9]*" 
                                    onInput={ this.handleInput.bind(this) } 
                                    onChange={ this.handleChange.bind(this) }/>
                                    { this.state.phoneValidate.length > 0 && <span className="text-danger">{ this.state.phoneValidate }</span> }
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.name')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" 
                                        name="txt_name" 
                                        placeholder="Enter name..." 
                                        onChange={ this.handleChange.bind(this) }/>
                                { this.state.nameValidate.length > 0 && <span className="text-danger">{ this.state.nameValidate }</span> }

                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.birth')} </label>
                                <input type="date" className="form-control" name="dt_birth" 
                                        onChange={ this.handleChange.bind(this) }/>
                                { this.state.birthValidate.length > 0 && <span className="text-danger">{ this.state.birthValidate.map((err) => err) }</span> }       
                              </div>
                              <div className="row">
                                <div className="col-xl-6">
                                  <div className="form-group">
                                    <label>{t('visitor.label.identity')} </label>
                                    <input className="form-control" name="txt_identity" placeholder="Enter identity..." 
                                            onChange={ this.handleChange.bind(this) }/>
                                    { this.state.identityValidate.length > 0 && <span className="text-danger">{ this.state.identityValidate.map((err) => err) }</span> }       
                                  </div>
                                </div>
                                <div className="col-xl-6">
                                  <div className="form-group">
                                    <label>{t('visitor.label.issuedon')} </label>
                                    <input type="date" className="form-control" name="dt_issuedOn"
                                                        onChange={ this.handleChange.bind(this) }/>
                                    { this.state.issuedOnValidate.length > 0 && <span className="text-danger">{ this.state.issuedOnValidate.map((err) => err) }</span> }       
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.nation')}</label>
                                <input className="form-control" name="txt_nationality" placeholder="Enter your nationality..." 
                                    onChange={ this.handleChange.bind(this) }/>                             
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.address')} </label>
                                <input className="form-control" name="txt_address" placeholder="Enter address..."
                                    onChange={ this.handleChange.bind(this) }/>
                                    { this.state.addressValidate.length > 0 && <span className="text-danger">{ this.state.addressValidate.map((err) => err) }</span> }       

                              </div>
                              <div className="form-group">
                                <label>Email </label>
                                <input type="email" className="form-control" name="txt_email" placeholder="name@example.com"
                                    onChange={ this.handleChange.bind(this) }/>
                                { this.state.emailValidate.length > 0 && <span className="text-danger">{ this.state.emailValidate.map((err) => err) }</span> }       

                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.company')}</label>
                                <input className="form-control" name="txt_company" placeholder="Enter company name..."
                                    onChange={ this.handleChange.bind(this) }/>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.position')}</label>
                                <input className="form-control" name="txt_position" placeholder="Enter your position..."
                                    onChange={ this.handleChange.bind(this) }/>
                              </div>
                              <div className="form-group text-center">
                                <Link to={ "/checkin/module/" + this.props.match.params.customer_id }><button type="button" className="btn btn-light mr-4">{t('visitor.back')}</button></Link>
                                <button type="submit" className="btn btn-primary">{t('visitor.next')}</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withTranslation()(VisitorNew);