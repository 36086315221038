import React, { Component } from 'react';
import { Link, Prompt, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';


class Visitor extends Component {

    constructor(props){
      super(props);
      this.state = {
        phone_number : '',
        isRedirect : false,
        isBlocking : false,
        errs : [],
        customer_id : null,
        visitors : [],
        // About Visitor 
        visitor_id : '',
        address : '',
        name : '',
        birth : '',
        identity : '',
        nationality : '',
        issued : '',
        email : '',
        company : '',
        position : ''

      };
      this.handleChange = this.handleChange.bind(this);

    }


    handleValidation = (event) => {
          let target = event.target;
          const errors = [];
          if(target.txt_name.value === ""){
            errors.push("Tên không được để trống!");
          }

          if(target.dt_birth.value === ""){
            errors.push("Ngày sinh không được để trống!");
          }

          if(target.txt_phone_number.value === ""){
            errors.push("Số điện thoại không được để trống!");
          }
          if(target.txt_identity.value === ""){
            errors.push("CMND/CCCD không được để trống!");
          }
          
          if(target.dt_issuedOn.value === ""){
            errors.push("Ngày cấp không được để trống!");
          }

          if(target.txt_nationality.value === ""){
            errors.push("Quốc tịch không được để trống!");
          }

          if(target.txt_company.value === ""){
            errors.push("Công ty không được để trống!");
          }

          if(target.txt_position.value === ""){
            errors.push("Chức vụ không được để trống!");
          }

          if(target.txt_address.value === ""){
            errors.push("Địa chỉ không được để trống!");
          }

          // Email
          if(target.txt_email.value === ""){
            errors.push("Email không được để trống!");
          }

          if(typeof target.txt_email.value !== "undefined"){
              let lastAtPos = target.txt_email.value.lastIndexOf('@');
              let lastDotPos = target.txt_email.value.lastIndexOf('.');

              if (!(lastAtPos < lastDotPos && lastAtPos > 0 && target.txt_email.value.indexOf('@@') === -1 && lastDotPos > 2 && (target.txt_email.value.length - lastDotPos) > 2)) {
                  errors.push("Email is not valid");
                }
          }
        return errors;
    }

    componentDidMount(){

      let company = JSON.parse(localStorage.getItem("company_data"));

      let visitor = JSON.parse(localStorage.getItem("visitors_data"));
      this.setState({
        phone_number : visitor.phone_number,
        visitor_id : visitor.id,
        address : visitor.address,
        name : visitor.visitor_name,
        birth : visitor.birth,
        identity : visitor.identity_id,
        nationality : visitor.nationality,
        issued : visitor.issued_on,
        email : visitor.email,
        company : visitor.email,
        position : visitor.position,
        isBlocking : false,
        company_logo : company.company_logo
      });
      localStorage.setItem("visitor_id" , visitor.id);
    }
    
    handleSubmit(event){
      event.preventDefault();

      let notify = "";
      let validateErr = this.handleValidation(event);
      
      if(validateErr.length !== 0){
        for(let err in validateErr)
        {
          notify += validateErr[err] + "<br/>";
        }
        Swal.fire('Thông báo!', notify , "warning");
      }else{

        let data = {
          txt_name : event.target.txt_name.value,
          dt_birth : event.target.dt_birth.value,
		      txt_phone_number : event.target.txt_phone_number.value,
          txt_email : event.target.txt_email.value,
          txt_identity : event.target.txt_identity.value,
          dt_issuedOn : event.target.dt_issuedOn.value,
          txt_nationality : event.target.txt_nationality.value,
          txt_company : event.target.txt_company.value,
          txt_position : event.target.txt_position.value,
          txt_address : event.target.txt_address.value,
        };
        
        let api_url = "https://www.qior.com.vn/api/v1/visitors/updateVisitor/" + this.props.match.params.customer_id + "/" + this.state.visitor_id;
        fetch(api_url, {
          method : 'POST',
          headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json',
            // 'Access-Control-Allow-Origin' : 'http://localhost:3000'
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(result => {
          if(result.status_code === 200){
              event.target.reset();
              this.setState({
                isBlocking : false,
                isRedirect : true
              });
              return Swal.fire({
                icon: 'success',
                title: result.message,
                showConfirmButton: false,
                timer: 1500
              })
          }
          else if(result.status_code === 500){
              return Swal.fire('Lỗi!', result.message , "error");
          }
        })
        .catch(error => {
          console.log(JSON.parse(error));
        })
      }

      
    }


    handleChange(event){      
        this.setState({
          [event.target.name] : event.target.value,
          isBlocking : event.target.value.length > 0
        });
    }

    handleInput(event){
      let phone_num = (event.target.validity.valid) ? event.target.value : this.state.phone_number;
      this.setState({ phone_number : phone_num });
    }



    render() {
      const { t } = this.props;

      if(this.state.isRedirect)
      {
        let url = "/checkin/type-employee/" + this.props.match.params.customer_id;
        return <Redirect to={ url } />
      }

        return (
          
            <div>
              <Prompt when={this.state.isBlocking} message={ location => (`Bạn có chắc muốn chuyển trang ${location.pathname}. Việc thực hiện thao tác này khiến cho dữ liệu bạn đang điền trong form sẽ bị mất?` ) } />
            {/* Header */}
            {/* {this.props.match.params.visitor_type_id} */} 
            <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="card bg-secondary border-0 mb-0">
                      <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center">
                          <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />    
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <form encType="multipart/form-data" onSubmit={ (e) => this.handleSubmit(e) }>
                              <div className="form-group">
                                <label>{t('visitor.label.phone')} <span style={{color: 'red'}}>*</span></label>
                                <input type="text" className="form-control" 
                                    name="txt_phone_number"
                                    value={ this.state.phone_number }
                                    placeholder="Input your phone number ..." 
                                    pattern="[0-9]*" 
                                    onInput={ this.handleInput.bind(this) } 
                                    onChange={ this.handleChange.bind(this) }/>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.name')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" 
                                        name="txt_name" 
                                        value={ this.state.name }
                                        placeholder="Enter name..." 
                                        onChange={ this.handleChange.bind(this) }
                                        />
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.birth')} <span style={{color: 'red'}}>*</span></label>
                                <input type="date" className="form-control" name="dt_birth" 
                                        onChange={ this.handleChange.bind(this) }
                                        value={ this.state.birth }
                                        />
                              </div>
                              <div className="row">
                                <div className="col-xl-6 col-6">
                                  <div className="form-group">
                                    <label>{t('visitor.label.identity')} <span style={{color: 'red'}}>*</span></label>
                                    <input className="form-control" name="txt_identity" placeholder="Enter identity..." 
                                            onChange={ this.handleChange.bind(this) }
                                            value={ this.state.identity } />
                                  </div>
                                </div>
                                <div className="col-xl-6 col-6">
                                  <div className="form-group">
                                    <label>{t('visitor.label.issuedon')} <span style={{color: 'red'}}>*</span></label>
                                    <input type="date" className="form-control" name="dt_issuedOn"
                                                        onChange={ this.handleChange.bind(this) }
                                                        value={ this.state.issued } />
                                  </div>
                               </div>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.nation')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" name="txt_nationality" placeholder="Enter your nationality..." 
                                    onChange={ this.handleChange.bind(this) }
                                    value={ this.state.nationality }/>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.address')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" name="txt_address" placeholder="Enter address..."
                                    onChange={ this.handleChange.bind(this) }
                                    value={ this.state.address }/>
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <input className="form-control" name="txt_email" placeholder="name@example.com"
                                    onChange={ this.handleChange.bind(this) }
                                    value={ this.state.email }/>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.company')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" name="txt_company" placeholder="Enter company name..."
                                    onChange={ this.handleChange.bind(this) }
                                    value={ this.state.company }/>
                              </div>
                              <div className="form-group">
                                <label>{t('visitor.label.position')} <span style={{color: 'red'}}>*</span></label>
                                <input className="form-control" name="txt_position" placeholder="Enter your position..."
                                    onChange={ this.handleChange.bind(this) }
                                    value={ this.state.position }/>
                              </div>
                              <div className="form-group text-center">
                                {/* <button type="submit" className="btn btn-success">Update</button> */}
                                <Link to={"/checkin/module/" + this.props.match.params.customer_id } ><button type="button" className="btn btn-light mr-4">{t('visitor.back')}</button></Link>
                                <Link to={"/checkin/type-employee/" + this.props.match.params.customer_id } ><button type="button" className="btn btn-primary">{t('visitor.next')}</button></Link>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withTranslation()(Visitor);