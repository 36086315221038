import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Employee from './Employee/Employee';
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';


class TypeEmployee extends Component {

    constructor(props){
        super(props);
        this.state = {
            cb_visitorType : 0,
            isShowEmployee : false,
            visitors_type : [],
            visitor_type_id : null,
            employee_id : null,
            employee_id_fromChild : null,
            isRedirect : false
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleCallback = (childData) => {

        // localStorage.setItem('employee_id', childData);
        this.setState({ employee_id : childData });
        console.log(childData);
    }

    

    handleChange(event) {
        this.setState({ 
            isShowEmployee : true ,
            cb_visitorType : event.target.value,
            visitor_type_id : event.target.value
        });
        localStorage.setItem('visitor_type_id', event.target.value);      
        let type_id = event.target.value;
        for (let type of this.state.visitors_type){
            if(type.id === type_id)
            {
                if(type.employee_id !== null)
                {
                    this.setState({
                        employee_id : type.employee_id
                    });
                }
                else{
                    this.setState({
                        employee_id : 'default'
                    });
                }
            }
        }
    }

    goNext = () => {
        if(this.state.visitor_type_id === null){
            return Swal.fire('Thông báo!', "Vui lòng chọn mục đích gặp!", "warning");
        }
        else{
            if(this.state.employee_id === 'default'){
                return Swal.fire('Thông báo!', "Vui lòng chọn nhân viên!", "warning");
            }
            else{
                this.setState({ isRedirect : true });
            }
        }
    }
    

    componentDidMount(){
        this.refreshList();
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        })
    }

    refreshList(){
        let api_url = "https://www.qior.com.vn/api/v1/visitors_type/show/" + this.props.match.params.customer_id;
        fetch(api_url)
        .then(response => response.json())
        .then(data => {
            this.setState({ visitors_type : data.data });
        });
    }
    

    render() {
        const { t } = this.props;

        let isShowEmployee = this.state.isShowEmployee;
        let elmForm = null;

        if(isShowEmployee === true)
        {
            elmForm = <Employee parentCallback = {this.handleCallback} employee_id={ this.state.employee_id } customer_id={ this.props.match.params.customer_id }></Employee>
        }

        if(this.state.isRedirect)
        {
            let url = "/checkin/meeting-sign/" + this.props.match.params.customer_id;
            return <Redirect to={url} />
        }
        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-8 col-md-8 px-5 text-center">
                       
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                                <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                <h3>{t('visitor_type.title')}</h3>
                            
                                <div className="form-group">
                                    <select value={ this.state.cb_visitorType } name="cb_visitorType" className="form-control" onChange={ this.handleChange }>
                                        <option value={null}>{t('visitor_type.choose')}</option>
                                        { this.state.visitors_type.map(visitor_type => 
                                            <option key={ visitor_type.id } value={ visitor_type.id }>{ visitor_type.description }</option>
                                        )}
                                    </select>
                                </div>
                                {elmForm}
                                <div className="form-group">
                                    <button className="btn btn-success col-xl-4 col-8" onClick={ this.goNext }>{t('visitor.next')}</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TypeEmployee);