import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { SemipolarLoading } from 'react-loadingg';


class Checkin extends Component {

    constructor(props){
        super(props);
        this.state = {
            company_logo : '',
            company_name : '',

        }
    }

    

    componentDidMount(){
        
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo,
            company_name : company.company_name
        })

    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg- col-md-8 px-5">
                        <h1 className="text-white">{ this.state.company_name + " " + t('header.welcome')}</h1>
                        {/* <p className="text-lead text-white">{t('header.description')}
                        </p> */}
                    </div>
                    { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                            <div className="form-group">
                                <Link to={"/checkin/face-recognize/" + this.props.match.params.customer_id}><button className="btn btn-success col-xl-4 col-8">{t('module.fr')}</button></Link> 
                            </div>
                            {/* <div className="form-group">
                                <Link to={"/checkin/face-recognize-real-time/" + this.props.match.params.customer_id}><button className="btn btn-primary col-xl-4 col-8">Face Real Time</button></Link> 
                            </div> */}
                            <div className="form-group">
                                <Link to={"/checkin/qr-code/" + this.props.match.params.customer_id}><button className="btn btn-danger col-xl-4 col-8">{t('module.qr')}</button></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Checkin);