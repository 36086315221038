import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

class QRCheckin extends Component {
  state = {
    isRedirect: false,
    device_code : null,
  }

  componentDidMount(){
    let company = JSON.parse(localStorage.getItem("company_data"));
    let device_code = localStorage.getItem("device_id");
    this.setState({
        company_logo : company.company_logo,
        device_code : device_code,
    })
  }

  handleScan = data => {
    if (data) {
        

        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/get-info-by-qr/" + this.props.match.params.customer_id;
        let bodyFormData = new FormData();

        bodyFormData.append('code', data);
        axios({
            method: "post",
            url: api_url,
            data : bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
        })
        .then(async result => {
          if(result.data.status === 200)
          {
            // console.log(result.data);
            localStorage.setItem('visitor', JSON.stringify(result.data.visitor));
            localStorage.setItem('module', 'qr');
            this.setState({
                isRedirect: true
            });
          }
          else if(result.data.status === 202)
          {
              swal({
                icon : "error",
                title: "Lỗi 500!",
                text: result.data.visitor,
                type: "error",
                timer: 3000,
                showConfirmButton: false
              }).then(function() {
                  let url_home = "/" + this.props.match.params.customer_id + "/" + this.state.device_code;
                  window.location.href = url_home;
              });
          }
          
        });
        

    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    if(this.state.isRedirect)
    {
        let url = "/checkin/face-recognize/visitor/" + this.props.match.params.customer_id;
        // return <Redirect to={ url } />
        window.location.href = url;
    }
    return (
        <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                {/* {/* <div className="row"> */}
                                <QrReader
                                    facingMode = {'environment'}
                                    delay={300}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    style={{ width: '100%' }}
                                    />
                                {/* </div> */} 
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    )
  }
}
export default QRCheckin
