import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import axios from 'axios';
import SubmitData from '../Checkin/SubmitData';



// const socket_url = "wss://face.pvndns.net/pub/facerecognize";


class CapturePhoto extends Component {
    constructor (props) {
        super(props);
        this.state = {
            imageSrc : "",
            isCapture : false,
            isProcess : false,
            isRedirect : false,
            isUse : false,
            visitor_type : [],
            device_code : null,
            ws : null,
            socket_api : null
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
      };
    
    capture = () => {
        this.setState({
            imageSrc : this.webcam.getScreenshot(),
            isCapture : true
        })
      };
    
    retake = () => {
        this.setState({
            imageSrc : "",
            isCapture : false,
            isUse : false
        })
      };
    
    useImg =() => {
        this.setState({
            isUse : true
        });
        localStorage.setItem('img', this.state.imageSrc);
        let data =  {
            "command":"visitor_mapping",
            
        };
        console.log(data);
    }

    handleSubmit = (event) => {
        
        event.preventDefault();

        if(this.state.visitor_type.isActive_things_carry !== 1 && this.state.visitor_type.isActive_nda !== 1)
        {
            this.submit();  
        }

        this.setState({
            isBlocking : false,
            isRedirect : true, 
            isProcess : false
        });
        
    }

    timeout = 250; // Initial timeout duration as a class variable

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = (data) => {
        var ws = new WebSocket(this.state.socket_api);
        let that = this; // cache the this
        var connectInterval;
        // websocket onopen event listener
        ws.onopen = () => {
            console.log("Websocket connected");
            // if(data !== "not send")
            // {
            //     ws.send(JSON.stringify(data));
            // }
            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    returnToHome = () => {

        this.interval = setInterval(() => {
            let redirect = localStorage.getItem("redirect");
            let url_home = localStorage.getItem("url_home");
            if(redirect === "home")
            {
                // console.log(redirect);
                this.setState({
                    isRedirect : true, 
                    isProcess : false,
                });
                localStorage.clear();
                swal({
                    icon : "success",
                    title: "Success!",
                    text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                    }).then(function() {
                    
                    window.location.href = url_home;
                });
            }
        }, 1000);

        
    };


    async submit() {
        let visitor = JSON.parse(localStorage.getItem('visitor'));

        // get object meeting employee or company
        let employee_id = localStorage.getItem('employee_id');
        let company_id = localStorage.getItem('company_id');
        let device_id = localStorage.getItem('device_id');
        let department_id = localStorage.getItem('department_id');

        // Get visitor type
        let visitor_type = JSON.parse(localStorage.getItem('visitor_type'));

        // Get detail meeting
        let detail = JSON.parse(localStorage.getItem('detail'));

        // Get thing carrying
        let thing = JSON.parse(localStorage.getItem('thing_carrying'));
        let img = localStorage.getItem('img');

        // Get face image
        let face_img = localStorage.getItem('face_img');
        let image_id = localStorage.getItem('image_id');

        // Get meeting note
        let meeting_note = localStorage.getItem('meeting_note');

        let bodyFormData = new FormData();

        bodyFormData.append('device_id', device_id);
        // Fill data visitor to form
        bodyFormData.append('visitor_id', visitor.visitor_id === null ? '' : visitor.visitor_id);
        bodyFormData.append('txt_name', visitor.txt_name);
        bodyFormData.append('dt_birth', visitor.dt_birth);
        bodyFormData.append('txt_phone_number', visitor.txt_phone_number);
        bodyFormData.append('txt_email', visitor.txt_email);
        bodyFormData.append('txt_identity', visitor.txt_identity);
        bodyFormData.append('dt_issuedOn', visitor.dt_issuedOn);
        bodyFormData.append('txt_nationality', visitor.txt_nationality);
        bodyFormData.append('txt_company', visitor.txt_company !== null ? visitor.txt_company : '-');
        bodyFormData.append('txt_position', visitor.txt_position);
        bodyFormData.append('txt_address', visitor.txt_address);

        // Choose employee
        
        bodyFormData.append('txt_note', meeting_note);
        // Choose visitor type
        bodyFormData.append('cb_purpose', visitor_type.id);
        // Choose detail meeting
        bodyFormData.append('dt_dateVisit', detail.dt_dateVisit);
        bodyFormData.append('dt_from', detail.dt_from);
        bodyFormData.append('dt_to', detail.dt_to);

        bodyFormData.append('cb_person', employee_id === null ? '' : employee_id);
        bodyFormData.append('cb_company', company_id === null ? '' : company_id);
        bodyFormData.append('cb_department', department_id === null ? '' : department_id);
        // Sign with face
        bodyFormData.append('img', img);
        bodyFormData.append('platform', "web");
        // Thing carrying
        if(thing !== null)
        {
            bodyFormData.append('txt_description', thing.txt_description);
            bodyFormData.append('img_thing', thing.img_thing  === null ? '' : thing.img_thing);
        }

         // Signature       
        if(this.state.trimmedDataURL !== null)
        {
            bodyFormData.append('sign', this.state.trimmedDataURL);
        }
        



        let customer_id = this.props.match.params.customer_id;
        let api_url = "https://www.qior.com.vn/api/v2/qiosk/visitor/store/" + customer_id;

        await axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy"
            },
        })
        .then(result => {
            // console.log(result.data);
            if(result.data.status_code === 200){
                
                let data =  {
                    "command":"visitor_mapping",
                    "userid": image_id, 
                    "visitorid" : result.data.visitor_id
                };
                
                let url_home = "/" + this.props.match.params.customer_id + "/" + this.state.device_code

                localStorage.setItem('face_signup',  JSON.stringify(data));
                localStorage.setItem('url_home',  url_home);
                // this.valid_visitor_mapping();
            }
            else if(result.data.status_code === 500)
            {
                return Swal.fire('Lỗi 500!', result.data.message , "error");
            }
        })
        .catch(error => {
            console.log(JSON.parse(error));
        });

        if(image_id !== null)
        {
            let face_signup = JSON.parse(localStorage.getItem('face_signup'));
            let ws = this.state.ws;
            ws.send(JSON.stringify(face_signup));
            ws.onmessage = function (event) {
                let message = JSON.parse(event.data);
                if(message.result === true)
                {
                    localStorage.setItem("redirect", "home");
                }
            }
        }
        else
        {
            localStorage.setItem("redirect", "home");
        }

    }
    

    componentDidMount(){

        let company = JSON.parse(localStorage.getItem("company_data"));

        let visitor_type = JSON.parse(localStorage.getItem("visitor_type"));
        let device_code = localStorage.getItem("device_id");
        let socket_api = localStorage.getItem("socket_api");
        // console.log(setting);
        this.setState({
            company_logo : company.company_logo,
            visitor_type : visitor_type,
            device_code : device_code,
            socket_api : socket_api,
        });

        this.connect("not send");  
        this.returnToHome();    

        
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({
            ws : null,
        });
    }

    render() {

        if(this.state.isRedirect)
        {
            if(this.state.visitor_type.isActive_things_carry === 1)
            {
                let url = "/checkin/thing-carrying/" + this.props.match.params.customer_id;
                // return <Redirect to={ url } />
                window.location.href = url;
            }
            else if(this.state.visitor_type.isActive_nda === 1)
            {
                let url = "/checkin/nda/" + this.props.match.params.customer_id;
                // return <Redirect to={ url } />
                window.location.href = url;
            }
          
        }

        let isCapture = this.state.isCapture;
        let elmForm = null;
        if(isCapture === true)
        {
            // elmForm = <TakePhoto imgsrc={ this.state.imageSrc }/>;
            elmForm = <>
                    <div className="row justify-content-center">
                        <form onSubmit={ (e) => this.handleSubmit (e) }>
                            <div className="form-group">
                                <h3 className="text-warning">Vui lòng tháo khẩu trang!</h3>
                                <TakePhoto imgsrc={ this.state.imageSrc }/>
                                <input type="hidden" name="img" value={ this.state.imageSrc }/>
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-info" onClick={this.retake}>Retake</button>
                                <button type="button" className="btn btn-success" onClick={ this.useImg }>Use</button>
                            </div>                    
                            { this.state.isUse && <button type="submit" className="btn btn-primary">NEXT STEP</button> }
                        </form>
                    </div>


            </>
        }
        else{
            elmForm = <>
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <button type="button" className="btn btn-primary form-control col-xl-4 col-8" onClick={this.capture}>Capture</button>
                        </div>
                    </>

        }
        

        return (
            
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                {/* <div className="row">
                                <div className="col-xl-6">
                                    <Webcam width="100%" audio={false} ref={this.setRef} screenshotFormat="image/jpeg"/>
                                </div>
                                <div className="col-xl-6"> */}
                                    { elmForm }
                                    { this.state.isProcess && <SemipolarLoading color={'#33D7FF'}/> }
 
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


class TakePhoto extends Component {
    
    render() {
    
        const imgsrc = this.props.imgsrc;
        return (
          <div>
            
            <img width="100%" src={ imgsrc } alt=""/>


          </div>
        );
      }
}


export default CapturePhoto;