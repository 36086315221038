import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { withTranslation } from 'react-i18next';


class VaccineNew extends Component {

    constructor(props){
        super(props);
        this.state = {
            injectionImgValidate_1 : [],
            injectionImgValidate_2 : [],
            cb_injection_1 : '',
            img_injection_1 : '',
            vaccine_id : '',
            vaccinated : false,
            isRedirect : false,
            isBlocking : false,
        };
        this.handleChange = this.handleChange.bind(this);

    }
    

    hasVaccinated = () => {
        this.setState({
            vaccinated : true
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleValidation = (bodyFormData, event) => {

        let api_url = "https://www.qior.com.vn/api/v1/validator/vaccine-new";

        axios({
            method: "post",
            url: api_url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(result => {
          if(result.data.status === 200){

                if(event.target.img_injection_1.files[0] !== undefined){

                    this.getBase64(event.target.img_injection_1.files[0], (res) => {
                        localStorage.setItem('vaccine_img1', res);

                    });
                }

                let data = {
                    'vaccine_id' : this.state.vaccine_id,
                    'cb_injection_1' : event.target.cb_injection_1.value,
                };
                
                localStorage.setItem('vaccine', JSON.stringify(data));

                this.setState({
                    isBlocking : false,
                    isRedirect : true, 
                });
            }
            else if(result.data.status === 404){
                let errors = result.data.error;
                
                this.setState({
                    injectionImgValidate_1 : !!errors.img_injection_1 ? errors.img_injection_1 : [],
                });
            }
          })
          .catch(error => {
            console.log(JSON.parse(error));
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.append('cb_injection_1', event.target.cb_injection_1.value);
        if(event.target.img_injection_1.files[0] !== undefined){
            
            bodyFormData.append('img_injection_1', event.target.img_injection_1.files[0] !== null ? event.target.img_injection_1.files[0] : '');
        }

        this.handleValidation(bodyFormData, event);
    }

    renderVaccine(){

        const { t } = this.props;

        let url = "/checkin/type-employee/" + this.props.match.params.customer_id;

        if(this.state.vaccinated === false)
        {
            return <>
                    <div className="form-group">
                        <h1>{t('vaccine-des')}</h1>
                    </div>

                    <div className="form-group">
                        <button onClick={ this.hasVaccinated } id="btn-yes" className="btn btn-success">{t('vaccine-yes')}</button>
                        <Link to={ url }><button id="btn-no" className="btn btn-danger">{t('vaccine-no')}</button></Link>
                    </div>
            </>
        }
    }

    handleChange(event){      
        this.setState({
          [event.target.name] : event.target.value,
          isBlocking : event.target.value.length > 0
        });
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        let visitor = JSON.parse(localStorage.getItem("visitor"));

        this.setState({
            company_logo : company.company_logo
        });

        let api_url = "https://www.qior.com.vn/api/v1/visitors/get-vaccine/" + this.props.match.params.customer_id + "/" + visitor.visitor_id;

        axios.get(api_url)
        .then(result => {

            if(result.data.vaccine !== null)
            {
                this.hasVaccinated();
                this.setState({
                    vaccine_id : result.data.vaccine.id,
                    cb_injection_1 : result.data.vaccine.vaccine_name_1,
                    img_injection_1 : result.data.vaccine.injection_image_1
                });

            }
        });

    }


    render() {
        const { t } = this.props;

        if(this.state.isRedirect)
        {
            let url = "/checkin/type-employee/" + this.props.match.params.customer_id;
            return <Redirect to={ url } />
        }



        let elmForm = null;
        let injection_vaccine = null;
        let vaccinated = this.state.vaccinated;

        if(vaccinated === true)
        {
            injection_vaccine = <>
            <div className="row">
                <div className="form-group col-xl-4">
                    <h3 className="text-left">{t('vaccine.injection')} </h3>
                    <input type="number" min="1" inputMode="numeric" pattern="[0-9]*" title="Non-negative integral number" className="form-control" name="cb_injection_1" value={ this.state.cb_injection_1 }  onChange={ this.handleChange }/>
                </div>
                <div className="form-group col-xl-8">
                    <h3 className="text-left">{t('vaccine.image')}</h3>
                    <input type="file" name="img_injection_1" className="form-control" onChange={this.onFileChange} />
                    { this.state.injectionImgValidate_1.length > 0 && <p className="text-danger text-left">{ this.state.injectionImgValidate_1.map((err) => err) }</p> }
                </div>
            </div> 
            
            <div className="form-group">
                <Link to={ "/checkin/visitor-sign/" + this.props.match.params.customer_id }><button className="btn btn-light mr-4">{t('visitor.back')}</button></Link>
                <button type="submit" className="btn btn-success">{t('visitor.next')}</button>
            </div>
            </>

            
            elmForm = <>
                <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <form onSubmit = { (e) => this.handleSubmit(e) }>

                            { injection_vaccine }
                            </form>
                        </div>                    
                </div>
                
            </>
        }

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                            <div className="card-body px-lg-5 py-lg-5">
                                <div className="text-center">
                                    <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                                    { this.renderVaccine() }
                                    { elmForm }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(VaccineNew);