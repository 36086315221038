import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { loadModels, getFullFaceDescription,extractFaceFromBox, createMatcher } from '../../../../api/face';
import io from "socket.io-client";
import swal from 'sweetalert';
import axios from 'axios';


// let endPoint = "https://www.qior.com.vn"; // http://www.qior.com.vn:5002
// // let endPoint = "http://210.245.55.12:3006";
// let socket = io.connect(`${endPoint}`);


// Import face profile
const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 160;

class FaceRecognitionOut extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.state = {
      fullDesc: null,
      detections: null,
      descriptors: null,
      faceMatcher: null,
      match: null,
      face : null,
      company_logo : ""
    };
  }

  componentWillMount = async () => {
    await loadModels();
    // this.setState({ faceMatcher: await createMatcher(JSON_PROFILE) });
    this.setInputDevice();
  };

  componentDidMount(){
    let company = JSON.parse(localStorage.getItem("company_data"));
    this.setState({
        company_logo : company.company_logo
    })

  }

  setInputDevice = () => {
    navigator.mediaDevices.enumerateDevices().then(async devices => {
      let inputDevice = await devices.filter(
        device => device.kind === 'videoinput'
      );
      if (inputDevice.length < 2) {
        await this.setState({
          facingMode: 'user'
        });
      } else {
        await this.setState({
          facingMode: { exact: 'environment' }
        });
      }
      this.startCapture();
    });
  };

  startCapture = () => {
    this.interval = setInterval(() => {
      this.capture();
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  capture = async () => {
    if (!!this.webcam.current) {

      // Get face Detection Box
      await getFullFaceDescription(
        this.webcam.current.getScreenshot(),
        inputSize
      ).then(fullDesc => {
        if (!!fullDesc) {
          this.setState({
            detections: fullDesc.map(fd => fd.detection),
            descriptors: fullDesc.map(fd => fd.descriptor)
          });

          // Extract face from webcam
          let customer_id = this.props.match.params.customer_id;

          let detections = fullDesc.map(fd => fd.detection);
          if(detections.length > 0)
          {
            extractFaceFromBox(this.webcam.current.getScreenshot(), detections[0].box
            ).then(result => {
              let isRealtime = true
              // socket.emit("message", { result, customer_id, isRealtime});
              // socket.once("message", result => {
              //     if(result.status_code === 210)
              //     {
              //         swal({
              //             icon : "error",
              //             title: "Error!",
              //             text: result.message,
              //             type: "error",
              //             timer: 1000,
              //             showConfirmButton: false
              //         })
              //     }
              //     else{
              //         this.setState({
              //           face: result.visitor_name,
              //         });

              //         let api_url = "https://www.qior.com.vn/api/v1/visitor_detail/checkout/" + customer_id;
              //         const url = "/" + customer_id;
              //         // // redirect                        
              //         let bodyFormData = new FormData();
                      
              //         bodyFormData.append('visitor_id', result.id);
              //         bodyFormData.append('detail_id', 'no-id');
              //         axios({
              //             method: "post",
              //             url: api_url,
              //             data: bodyFormData,
              //             headers: { "Content-Type": "multipart/form-data" },
              //         })
              //         .then(res => {
              //             if(res.status === 200){
              //                 this.setState({
              //                     isRedirect : true, 
              //                     message : ''
              //                 });
              //                 const audioEl = document.getElementsByClassName("audio-element")[0]
              //                 audioEl.play()
              //                 swal({
              //                     icon : "success",
              //                     title: "Success!",
              //                     text: "CẢM ƠN QUÝ KHÁCH '"+ result.visitor_name +"'. XIN VUI LÒNG LIÊN HỆ QUẦY LỄ TÂN ĐỂ NHẬN LẠI GIẤY TỜ TÙY THÂN!",
              //                     type: "success",
              //                     timer: 3000,
              //                     showConfirmButton: false
              //                 }).then(function() {
                  
              //                     window.location.href = url;
              //                 });
              //             }else if(res.status === 205)
              //             {
              //                 this.setState({
              //                     message : ''
              //                 });
              //                 let url_err = "/checkout/module/" + customer_id;

              //                 swal({
              //                     icon : "error",
              //                     title: "Lỗi 205!",
              //                     text: 'Không tìm thấy dữ liệu. Bạn chưa đăng ký gặp!!!',
              //                     type: "error",
              //                     timer: 3000,
              //                     showConfirmButton: false
              //                 }).then(function() {
              //                   window.location.href = url_err;
              //               });
              //             }
              //         })
              //         .catch(error => {
              //             console.log(JSON.parse(error));
              //         })
              //     }
              // });
            });
          }
          
        }
      });


      if (!!this.state.descriptors && !!this.state.faceMatcher) {
        let match = await this.state.descriptors.map(descriptor =>
          this.state.faceMatcher.findBestMatch(descriptor)
        );
        this.setState({ match });
      }
    }
  };

  render() {
    const { detections, match, face } = this.state;
    let videoConstraints = null;
    videoConstraints = {
      width: WIDTH,
      facingMode: 'user'
    };

    let drawBox = null;
    if (!!detections) {

      drawBox = detections.map((detection, i) => {
        let _H = detection.box.height;
        let _W = detection.box.width;
        let _X = detection.box._x;
        let _Y = detection.box._y;
        
        return (
          <div key={i}>
            <div
              style={{
                border: 'solid',
                borderColor: 'blue',
                height: _H,
                width: _W,
                transform: `translate(${_X}px,${_Y}px)`
              }}
            >
              <p style={{ fontWeight: 'bold' }}>{face}</p>
              {!!match && !!match[i] ? (
                <p
                  style={{
                    backgroundColor: 'blue',
                    border: 'solid',
                    borderColor: 'blue',
                    marginTop: 0,
                    color: '#fff',
                    transform: `translate(-3px,${_H}px)`
                  }}
                >
                  {match[i]._label}
                </p>
              ) : null}
            </div>
          </div>
        );
      });
    }

    return (
        
        <div>
        {/* Header */}
        <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12">
                    <div className="card bg-secondary border-0 mb-0">
                      <div className="card-body px-lg-5 py-lg-5">
                          <div className="text-center">
                              <img style={{ marginTop:"4px", marginBottom: "4px"}} width="200px" height="200px" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                              <div className="row justify-content-center">
                                  <div className="col-xl-6">
                                      <div
                                        className="Camera"
                                        style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                        }}
                                      >
                                      <div
                                      style={{
                                          width: "100%",
                                          height: HEIGHT
                                      }}
                                      >
                                        <div>
                                            {!!videoConstraints ? (
                                            <div style={{ position: 'absolute' }}>
                                                <Webcam
                                                audio={false}
                                                width="100%"
                                                ref={this.webcam}
                                                screenshotFormat="image/jpeg"
                                                videoConstraints={videoConstraints}
                                                />
                                            </div>
                                            ) : null}
                                            {!!drawBox ? drawBox : null}
                                        </div>
                                        {/* <img src={this.state.face}/> */}
                                      </div>
                                      </div>
                                        <audio className="audio-element">
                                            <source src="/assets/sound-effect-GOODBYE.mp3" type="audio/mpeg"/>
                                        </audio>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
      
    );
  }
}

export default withRouter(FaceRecognitionOut);