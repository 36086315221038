import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { SemipolarLoading } from 'react-loadingg';
// import LoadingScreen from 'react-loading-screen';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

class Home extends Component {

    constructor(props){
        super(props);
        this.state = {
            company_logo : '',
            isProcess : true,
            feature : true,
        };
    }

    componentDidMount(){
        const getSetting = async () => {
            let api_url = "https://www.qior.com.vn/api/v2/qiosk/setting/get/" + this.props.match.params.customer_id + "/" +this.props.match.params.device_code;
            
            await axios({
                method: "get",
                url: api_url,
                headers: { "X-Authorization" : "KIJO0M1VqDT5fZ40VmmYp8TSulxMYKopKEEYpUNPAHcsKiGX91xNYZ1n3Yw29yzy" },
            })
            .then(async result => {
                // console.log(result.data);
                if(result.data.code === 202)
                {
                    return Swal.fire('Lỗi 404!', result.data.message, "error");
                }
                // console.log(result.data.setting);
                if(result.data.setting.web_check_in === 0)
                {
                    this.setState({
                        isProcess : false,
                        feature : false,
                    });
                    return Swal.fire('Thông báo', "Quản trị đã tắt tính năng này!", "error");
                }
                else if(result.data.setting.web_check_in === 1)
                {
                    localStorage.setItem("company_data", JSON.stringify(result.data.company));
                    localStorage.setItem("setting", JSON.stringify(result.data.setting));
                    localStorage.setItem("socket_api", result.data.AI_tech);
                    localStorage.setItem("location_type", result.data.location_type);
                    localStorage.setItem("device_id", this.props.match.params.device_code);
                    this.setState({
                        isProcess : false,
                        company_logo : result.data.company.company_logo,
                        company_name : result.data.company.company_name
                    });
                }
                
            });
        }

        getSetting();
    }

    render() {
        const { t } = this.props;
        let elementLoading = "";
        if(this.state.isProcess)
        {
            elementLoading = <SemipolarLoading color={'#33D7FF'}/>;
        }

        return (
            
            <div>
                { this.state.feature && 
                    <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                        <div className="container">
                        
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg- col-md-8 px-5">
                                    <h1 className="text-white">{ this.state.company_name + " " + t('header.welcome')}</h1>
                                    {/* <p className="text-lead text-white">{t('header.description')}.
                                    </p> */}
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="card bg-secondary border-0 mb-0">
                                        <div className="card-body px-lg-5 py-lg-5">
                                            { this.state.isProcess === true ? <SemipolarLoading color={'#33D7FF'}/> : (
                                            <div className="text-center">
                                                <img width="200px" height="200px" className="mb-4" src={ this.state.company_logo } alt="" /><br />
                                                <div className="form-group">
                                                    <Link to={"/checkin/module/" + this.props.match.params.customer_id }><button className="btn btn-success col-xl-4 col-8">{t('home.meeting')}</button></Link>
                                                </div>
                                                <div className="form-group">
                                                    <Link to={"/checkout/module/" + this.props.match.params.customer_id }><button className="btn btn-danger col-xl-4 col-8">Check out</button></Link>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(Home);