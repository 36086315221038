import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';


class Checkin extends Component {

    constructor(props){
        super(props);
        this.state = {
            company_logo : '',
            isGroup : null,
            numberPeo : null,
            isRedirect : false
        };
    }

    componentDidMount(){
        let company = JSON.parse(localStorage.getItem("company_data"));
        this.setState({
            company_logo : company.company_logo
        });

    }



    render() {


        const { t } = this.props;

        if(this.state.isRedirect)
        {
            let url = "/checkin/visitor-sign/" + this.props.match.params.customer_id;
            return <Redirect to={url} />
        }

        return (
            <div>
                {/* Header */}
                <div className="header py-7 py-lg-8 pt-lg-9" style={{backgroundImage: "linear-gradient(to bottom right, #96BAFF, #516BEB)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg- col-md-8 px-5">
                        <h1 className="text-white">{t('header.welcome')}</h1>
                        <p className="text-lead text-white">{t('header.description')}
                        </p>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="card bg-secondary border-0 mb-0">
                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center">
                            <img width="200px" height="200px" className="mb-4" src={"https://www.qior.com.vn/" + this.state.company_logo} alt="" /><br />
                            {/* <div className="form-group">
                                <h3>Please choose your type</h3>
                                <select name="cb_visitorType" className="form-control col-xl-4 offset-xl-4" onChange={ this.handleChange }>
                                    <option value={null}>Please choose your purpose</option>
                                    <option value="1">Đi một mình</option>
                                    <option value="2">Đi theo nhóm</option>
                                </select>
                            </div> */}
                            <div className="form-group">
                                <Link to={"/checkin/visitor-sign/" + this.props.match.params.customer_id}><button className="btn btn-success col-xl-4 col-8">{t('home.new-register')}</button></Link> 
                            </div>
                            <div className="form-group">
                                <Link to={"/checkin/module/" + this.props.match.params.customer_id}><button className="btn btn-danger col-xl-4 col-8">{t('home.registered')}</button></Link> 
                            </div>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Checkin);